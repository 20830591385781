.ui-widget {
	font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
	background: $aquamarine;
	border: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ui-datepicker {
	padding: 0;

	.ui-datepicker-header {
		border: none;
		background: transparent;
		font-weight: normal;
		font-size: 15px;
	}

	.ui-datepicker-header .ui-state-hover {
		background: transparent;
		border-color: transparent;
		cursor: pointer;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
	}

	.ui-datepicker-title {
		margin-top: .4em;
		margin-bottom: .3em;
		color: #e9f0f4;
	}

	.ui-datepicker-prev-hover,
	.ui-datepicker-next-hover,
	.ui-datepicker-next,
	.ui-datepicker-prev {
		top: .9em;
		border:none;
	}

	.ui-datepicker-prev-hover {
		left: 2px;
	}

	.ui-datepicker-next-hover {
		right: 2px;
	}

	.ui-datepicker-next span,
	.ui-datepicker-prev span {
		background-image: url(../img/ui-icons_ffffff_256x240.png);
		background-position: -32px 0;
		margin-top: 0;
		top: 0;
		font-weight: normal;
	}

	.ui-datepicker-prev span {
		background-position: -96px 0;
	}

	table {
		margin: 0;
	}

	th {
		padding: 1em 0;
		color: #ccc;
		font-size: 13px;
		font-weight: normal;
		border: none;
		border-top: 1px solid #3a414d;
		color: $white;
	}

	td {
		background: $white;
		border: none;
		padding: 0;
	}

	td .ui-state-default {
		background: transparent;
		border: none;
		text-align: center;
		padding: .5em;
		margin: 0;
		font-weight: normal;
		color: $black;
		font-size: 16px;
	}

	.ui-state-disabled  {
		opacity: 1;
	}

	.ui-state-disabled .ui-state-default {
		color: $aquamarine;
		opacity: 0.5;
	}

	td .ui-state-active,
	td .ui-state-hover {
		background: $aquamarine;
		color: $white;
	}

	select {
		appearance: none;
		cursor: pointer;
		height: 30px;
		padding: 0 10px;
		border-radius: 0;
		box-shadow: none;
		outline: none;
	}
}

body.page-etudiant {

	.ui-widget,
	td .ui-state-active,
	td .ui-state-hover {
		background: $purple;
	}

	.ui-state-disabled .ui-state-default {
		color: $purple;
	}
}