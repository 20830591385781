@mixin statutBullet{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display:block;
  &.orange{
    background-color: $pastel-orange;
  }
  &.green{
    background-color: $apple;
  }
  &.blue{
    background-color: $sky-blue;
  }
  &.red{
     background-color:$warm-pink;
  }
  &.grey{
     background-color:$warm-grey;
  }
  &.yellow{
    background-color: $yellow;
  }
}
