.block-legend{
  padding: 18px 33px 3px 33px;
  border: solid 3px $white;
  margin-bottom: 30px;

  @media screen and (max-width: 1540px){
      width: 100%;
  }


  .list-legend{
    @include clearfix;
    margin-bottom: -15px;
    > li{
        float: left;
        margin-bottom: 15px;
        &:not(:last-child){
          margin-right: 40px;
        }

        .item{
          height: 15px;
          padding-left: 25px;
          font-size: 15px;
          line-height: 15px;
          position: relative;

          &:before{
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            left: 0;
          }

          &.orange{
            color:   $pale-orange;
            &:before{  background-color: $pastel-orange;  }
          }
          &.green{
            color:  $apple;
            &:before{  background-color: $apple;  }
          }
          &.blue{
            color:  $dodger-blue;
            &:before{  background-color: $sky-blue; }
          }
          &.red{
            color: $faded-red;
            &:before{  background-color:$warm-pink;  }
          }
          &.grey{
            color: $warm-grey;
            &:before{  background-color:$warm-grey;  }
          }
          &.yellow{
            color: $yellow;
            &:before{  background-color:$yellow;  }
          }
        }
    }
  }
}

.page-commande-archivees, .node-type-commande-archivees{
  .block-legend .list-legend > li{
    &.color-grey, &.color-orange, &.color-blue, &.color-yellow{
      display: none;
    }
  }
}

.page-commande-en-cours, .node-type-commande-en-cours{
  .block-legend .list-legend > li{
    &.color-grey, &.color-red{
      display: none;
    }

     &.color-blue{
      //margin-right: 0;
     }
  }
}
