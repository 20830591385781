/* COULEURS */

$aquamarine:    #00d8b7;
$pastel-orange: #fe7d14;
$dodger-blue:   #489eff;
$apple:         #79c54c;
$warm-grey:     #a1a1a1;
$barbie-pink:   #ff438e;
$black:         #333333;
$black-two:     #2b2b2b;
$warm-grey-two: #706f6f;
$greyish-brown: #4b4b4b;
$sky-blue:      #56a5ff;
$pale-orange:   #fe7d14;
$white:         #ededed;
$greyish:       #a9a9a9;
$white-two:     #e7e7e7;
$black-three:   #2c2c2c;
$black-four:    #1e1e1e;
$white-three:   #f3f3f3;
$faded-red:     #e44554;
$warm-pink:     #ff5686;
$yellow: 		#ebcd00;

$purple: 		#800bea;
$realBlack:		#000000;
$realWhite:		#ffffff;

$mainColor:     $aquamarine;
$secondColor:   $black;

/* COULEURS RESEAUX SOCIAUX */

$colorFacebook:  	  #3b5998;
$colorTwitter:  	  #00aced;
$colorInstagram:  	  #125688;
$colorYoutube:  	  #bb0000;
$colorGoogle: 		  #dd4b39;
$colorVimeo:  		  #4bf;
$colorLinkedin: 	  #0077b5;
$colorYoutube:  	  #dd2c28;
$colorWeibo:  		  #fd8149;
$colorPinterest: 	  #cb2026;
$colorViadeo:		  #f1532e;

/* FONT WEIGHT */

$fwThin: 		100;
$fwExtraLight:  200;
$fwLight: 		300;
$fwNormal:  	400;
$fwMedium:  	500;
$fwSemiBold:  	600;
$fwBold:  		700;
$fwExtraBold: 	800;
$fwUltraBold: 	900;



/* FONT FAMILY */

$ffT: 'Source Sans Pro', sans-serif;
$ffP: 'Source Sans Pro', sans-serif;
