#landing{
  background: url(../img/bg_landing.png) center center no-repeat;
  background-size: cover;
  min-height: 100%;
  padding: 100px 0px;

  .clientside-error{
    display: none !important;
  }


@include breakpoint(large up){
  @media screen and (min-height: 940px)  {
    height: 100%;
    padding: 0;
  }
}

  #landing_container{
    height: 100%;
    display: flex;
    align-items: center;
    user-select: center;

    .content{
      position: relative;
      width: 100%;
      h1{
        top: -70px;
        right: 0;
        position: absolute;
        z-index: 555;
        
        a{
          display: block;
          border-radius: 25px; 
          background: #fff; 
          display: block;
          padding: 13px 20px; 
          color: #fff; 
          background: $aquamarine;

          &:hover{
            background: #fff;
            color: $aquamarine; 

            i{
               @include global-houseGreen;
            }
          }
      

          i{
            display: inline-block;
            vertical-align: middle;
              @include global-houseWhite;
              margin-right: 20px;
          }

          span{
            @include hedear-link-text;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    .box{
      background: #fff;
      width: 100%;
      border-radius: 15px;
      position: relative;
      overflow: hidden;



      .left{
        @include breakpoint(large up){
          float: left;
          height: 630px;
          width: 50%;
        }

        @include breakpoint(medium down){
            padding: 40px;
        }
        background: url(../img/bg_landing_box.png) center center no-repeat;
        background-size: cover;

        display:flex;
        align-items: center;
        justify-content: center;



        #logo{
          display: block;
        }

      }

      .right{
          @include breakpoint(large up) {
            float: right;
            width: 50%;
            height: 630px;
        }

        @include breakpoint(medium down) {
          padding: 40px;
        }
        position: relative;

        display: flex;
        align-items: center;


        .inside{
          width: 100%;

          .etudiant_register {
            @include buttonRounded($aquamarine, $black-three, 215px);
            margin: auto;
            margin-top: 20px;
          }

          >h2{
            font-size: 25px;
            font-weight: 600;
            text-align: center;
            color: $black-three;
            margin-bottom: 30px;
            text-align: center;
            padding-left: 70px;
            padding-right: 57px;
            position: relative;

            @include breakpoint(large up){
              &:after{
                content: "";
                width: 200px;
                height: 5px;
                border-radius: 2.5px;
                background-color: $black-three;
                position: absolute;

                left: -100px;
                top: 10px;
              }
            }

            @include breakpoint(large down){
                padding-left: 30px;
                padding-right: 30px;
            }

            @include breakpoint(medium down) {
              padding: 0px;
            }
          }

          .content{

            width: 100%;
            padding-left: 70px;
            padding-right: 57px;

            @include breakpoint(large down) {
                padding-left: 30px;
                padding-right: 30px;
            }

            @include breakpoint(medium down) {
              padding: 0px;
            }

            > p{
              font-size: 16px;
            	font-weight: 600;
            	line-height: 1.25;
            	text-align: center;
            	color: $black-three;
              text-align: center;
              display: block;
              min-width: 1px;
              min-height: 1px;
            }

            form{
              width: 360px;
              max-width: 100%;
              margin: 0px auto;
              margin-top: 30px;
            }

            .link{
              font-size: 16px;
            	font-weight: 600;
            	text-align: center;
            	color: $black-three;
              display: block;
              text-align: center;
              margin-top:20px;

              &:hover{
                color: $mainColor;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(large up) {
    &.landing_register{
       #landing_container .box .left, #landing_container .box .right{
         height: 800px;
       }
    }
  }
}
