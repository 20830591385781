// Core Class Definition
// -------------------------

.#{$zmdi-icon-prefix} {
  display: inline-block;
  font: normal normal normal 14px/1 '#{$zmdi-font-name}';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin font($varIcon){

	content: $varIcon; 
	font-family: $zmdi-font-name;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}


				

