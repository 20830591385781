#page{
  padding-top: 35px;
  padding-bottom: 50px;

  #content_top{
    @include clearfix;
    padding-bottom: 60px;

    @include breakpoint(large down) {
      padding-bottom: 40px;
    }

    @include breakpoint(medium down) {
      padding-bottom: 10px;
    }

    h1{
      @include page-title;
    }
    #content_top_left{
      @include breakpoint(xlarge up) {
        float: left;
      }

      @media screen and (max-width: 1540px){
        margin-bottom: 25px;
      }
    }
    #content_top_right{

      @media screen and (max-width: 1540px){
          max-width: 60%;

          .declarer, .reclamation{
              float: right;
              margin-bottom: 20px;
          }

          .view-legende-statut-commande {
            margin-left: 0;
            float: right;
          }
      }

      @include breakpoint(large only) {
      .reclamation, .declarer{
          margin-top: -50px;
        }
      }

      @include breakpoint(xlarge up) {
        float: right;
      }

      @include breakpoint(large down) {
        max-width: 100%;
      }

      @include clearfix;
      > *{
        float: left;
        &:not(:first-child){
          margin-left: 50px;
        }
      }

      #activePopup{
          @include breakpoint(large down) {
            //margin-top: -135px;
          }

          @include breakpoint(small down) {
            margin-top: 30px;
          }
      }
    }

    .button{
        @include buttonRounded($aquamarine, $black-three, 235px);
    }

    .button_back{
      @include buttonRounded($aquamarine, $black-three, 165px, 'back');
      margin-bottom: 35px;
    }

    .button_statut{
      clear: both;
      float: right !important;
      @include buttonRounded($aquamarine, $black-three, 190px);

      @media screen and (max-width: 1540px){
        margin-top: 30px;
      }

      &#print_link{
        clear: none;
      }
    }
  }
}

body.node-type-commande {

  #content_top_left {
    margin-bottom: 30px;
  }

  &.node-type-commande-en-cours {

    #page #content_top #content_top_right .view-legende-statut-commande {
      margin-bottom: 30px;
      float: right;
    }
  }
}


body.page-mes-commandes {

  #content_top_left {
    margin-bottom: 30px;
  }
}