@mixin page-title {
	font-family: $ffP;
	font-size: 30px;
	font-weight: 600;
	line-height: 1.0;
	text-align: left;
	color: $black-two;
}

@mixin h-2-rte {
	font-family: $ffP;
	font-size: 20px;
	font-weight: 600;
	text-align: left;
	color: $black;
}

@mixin th-text {
	font-family: $ffP;
	font-size: 18px;
	font-weight: 600;

	text-align: left;
	color: $black-two;
}

@mixin h-3-title {
	font-family: $ffP;
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	color: $black;
}

@mixin h-3-rte {
	font-family: $ffP;
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	color: $aquamarine;
}

@mixin td-text {
	font-family: $ffP;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.25;
	text-align: left;
	color: $black-two;
}

@mixin td-date-text {
	font-family: $ffP;
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	color: $warm-grey;
}

@mixin label-text {
	font-family: $ffP;
	font-size: 16px;
	text-align: left;
	color: $greyish-brown;
}

@mixin placeholder-text {
	font-family: $ffP;
	font-size: 16px;
	text-align: left;
	color: $warm-grey-two;
}

@mixin p-rte {
	font-family: $ffP;
	font-size: 16px;
	line-height: 1.38;
	text-align: left;
	color: $black;
}

@mixin hedear-link-text {
	font-family: $ffP;
	font-size: 15px;
	font-weight: 600;
	text-align: left;
	
}
