#header{
  background-color: $white-three;
  @include clearfix;
  padding: 30px 0px;

  @include breakpoint(medium down){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 888;
    background: #eaeaea;

  }


  #header_left{
    float: left;
  }

  #header_right{
    float: right;
  }

  .link_home, .link_logout, .open_mobile_menu{

    i{
      display: inline-block;
      vertical-align: middle;
    }

     span{
      @include hedear-link-text;
      display: inline-block;
      vertical-align: middle;
      color: $mainColor; 

    }
    &:hover > span{
        color: $black-two;
    }
  }

  .link_home{
      @include breakpoint(medium down){
        display: none;
      }
    i{
      margin-right: 12px;
      @include global-houseGreen;
    }
    &:hover i{
      @include global-houseBlack;
    }
  }
  .link_logout{
    i{
      margin-right: 12px;
      @include global-logoutGreen;
    }
    &:hover i{
      @include global-logoutBlack;
    }
  }

  .open_mobile_menu{
    display: none;

    @include breakpoint(medium down){
      display: block;
    }

    .hamburger{
      display: inline-block;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}
