// Import setup

@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";


@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/mixins/misc.scss";

@import "setup/grid.scss";

@import "setup/cssowl.scss";
@import "setup/sprites/avantages.scss";
@import "setup/sprites/blocks.scss";
@import "setup/sprites/forms.scss";
@import "setup/sprites/global.scss";
@import "setup/sprites/menu.scss";
@import "setup/sprites/services.scss";
@import "setup/sprites/tbd.scss";

@import "fonts.css";

/* IMPORT CUSTOM MIXINS */

@import "mixins/text-styles.scss";
@import "mixins/buttons.scss";
@import "mixins/statut.scss";


/* IMPORT COMPONANTS */

@import "componants/material-iconic/material-design-iconic-font.scss";
@import "componants/dialog.scss";


/* IMPORT ELEMENTS */

@import "elements/tables.scss";
@import "elements/tables_mobile.scss";
@import "elements/forms.scss";
@import "elements/rte.scss";
@import "elements/pager.scss";
@import "elements/list-documents.scss";
@import "elements/hamburger.scss";
@import "elements/dialog.scss";
@import "elements/statut_select.scss";
@import "elements/buttons.scss";
@import "elements/datepicker.scss";



/* IMPORT GLOBALS */

@import "global.scss";
@import "structure.scss";
@import "landing.scss";
@import "sidebar.scss";
@import "header.scss";
@import "page.scss";
@import "page-etudiant.scss";
@import "print.scss";


/* IMPORT BLOCKS */
@import "blocks/block-legend.scss";