@include breakpoint(small down){
    .views-table{
      thead{
        display: none;
      }

      display: block;

       tbody, tr, td{
        display: block;
        width: 100%;
      }

      > tbody{
        > tr{
          border-bottom: solid 3px $white;
          padding-bottom: 30px;
          &:not(:last-child){
            margin-bottom: 30px;
          }
          > td{
            border-bottom: none;
            padding: 0px !important;
            padding-left: 130px !important;
            position: relative;
            min-height: 15px;

            &.views-field-field-etablissement .grey{
                margin-top: 5px;
            }

            &:not(:last-child){
              margin-bottom: 15px;
            }

            &.views-field-ops{
              @include clearfix;
              width: 100%;
              i{
                margin-top: 0;
              }
            }

            &.moreLink a{
              margin: 0 !important;
            }

            &:before{
              content: attr(data-label);
              position: absolute;
              margin-top: 3px;
              top: 0;
              left: 0;
              text-transform: uppercase;
              font-size: 12px;
              color: #a1a1a1;
              padding-right: 15px;
              width: 115px;
            }

            &.views-field-view-2, &.views-field-view-1{
              display: none;
            }

            .statut{
              margin: 0;
              margin-left: 12px;
            }

            .view-display-id-articles{
              ul{
                li{
                  &:before{
                    content: attr(data-qty) " x ";
                  }

                  &:after{
                    content: "(T : " attr(data-taille) ")";
                    font-style: italic;
                    font-weight: normal;
                  }
                }
              }
            }
          }


        }
      }


    }
}
