.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.dialog {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	pointer-events: none;
	z-index: 9999;
}

.dialog__overlay {
	position: absolute;
	z-index: 1;
	background: rgba(0, 0, 0, 0.7);
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;
}

.dialog--open .dialog__overlay {
	opacity: 1;
	pointer-events: auto;
}

.dialog__content {
	width: 90%;
	max-width: 940px;
	min-width: 290px;
	background: #fff;
	padding-top: 120px;
	padding-bottom: 115px;
	padding-left: 50px;
	padding-right: 50px;
	text-align: center;
	position: relative;
	z-index: 5;
	opacity: 0;
	border-radius: 5px;

  .cross{
    @include global-closeRed;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    &:hover{
      @include global-closeBlack;
    }
  }
}

.dialog--open .dialog__content {
	pointer-events: auto;
}

.dialog.dialog--open .dialog__content,
.dialog.dialog--close .dialog__content {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.dialog.dialog--open .dialog__content {
	-webkit-animation-name: anim-open;
	animation-name: anim-open;
}

.dialog.dialog--close .dialog__content {
	-webkit-animation-name: anim-close;
	animation-name: anim-close;
}

@-webkit-keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); transform: scale3d(1.1, 1.1, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); transform: scale3d(0.9, 0.9, 1); }
}
