.views-table{
  width: 100%;
  margin-bottom: 40px;

  @include breakpoint(large down){
    .views-field-field-numero-de-commande{
      display: none;
    }


  }

  thead{
    tr{
      th{
        @include th-text;
        padding-bottom: 20px;
        text-align: left;
        border-bottom: solid 3px $white;

         @include breakpoint(medium only){
          &:not(:last-child){
            padding-right: 30px;
          }
          font-size: 13px;
         }

        &.views-field-ops{
          padding-left: 35px;
        }

        &:last-child, &.views-field-name{
          text-align: center;
        }

        a{
          color: $black-two;
          position: relative;

          img{
            display: none !important;
          }

          &:after{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: $black transparent transparent transparent;
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
          }

          &.active{
            &:after{
              @include rotate(180deg);
            }
          }

          &:hover{
            color: $mainColor;
          }
        }
      }
    }
  }

  tbody{
    tr{
      td{
        @include td-text;
        padding: 35px 0px;
        border-bottom: solid 3px $white;
        vertical-align: top;
        @include breakpoint(medium only){
         &:not(:last-child){
           padding-right: 20px;
         }
        }


        &.views-field-field-etablissement{
          .grey{
            color: $warm-grey-two;
            margin-top: 15px;
          }
        }

        ul{
          > li{
            list-style: none;
            &:not(:last-child){
              margin-bottom: 20px;
            }
          }
        }

        &.le-contenu-de-ma-jolie-popup{
          display: none;
        }

        &.views-field-ops{
          padding-left: 35px;
          width: 135px;

          i{
            display: block;
              cursor: pointer;
              float: left;
              margin-top: -7px;

                @include breakpoint(medium only){

                    float: none;
                    margin-left:  auto !important;
                    margin-right: auto !important; 
                    &:not(:last-child){
                      margin-bottom: 10px;
                    }

                }

            &.button_refuser{
              @include global-refuser;

            }
            &.button_valider{
              @include global-valider;
                margin-right: 30px;

            }
          }
        }

        &.views-field-created{
        	@include td-date-text;
        }

        &.moreLink{
          @include clearfix;
          a{
            @include buttonSeeMore;
            margin: 0px auto;
            margin-top: -7px;

          }
        }

        .statut{
          @include statutBullet;
          margin: 0px auto;
          margin-top: 5px;
        }

      .view-display-id-prix{
          tbody{
            display: none;
          }

          table{
            margin-bottom: 0;
          }
        }

      }
    }
  }
}
