.statut_select{
  width: 360px;
  border: solid 1px $white-two;
  border-radius: 5px;
  position: relative;
  padding-top: 50px;
  @include transition(0.3s);
  max-height: 50px;
  overflow: hidden;

  &:hover{
    max-height: 500px;
  }

  &:after{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: $black transparent transparent transparent;
    position: absolute;
    top: 23px;
    right: 22px;
  }

  > li{
    height: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 38px;
    @include transition(0.3s);
    width: 100%;

    &.active{
      position: absolute;
      top: 0;
    }

    &:not(.active){
      cursor: pointer;

      &:hover{
        background: $white-two;
      }
    }

    .inside{
      height: 10px;
      font-size: 15px;
      line-height: 10px;
      padding-left: 25px;
      position: relative;
      text-align: left;

      &:before{
        width: 10px;
        height: 10px;
        content: "";
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.orange .inside{
      color: $pale-orange;
      &:before{
        background: $pale-orange;
      }
    }
    &.blue .inside{
      color: $sky-blue;
      &:before{
        background: $sky-blue
      }
    }
    &.green .inside{
      color: $apple;
      &:before{
        background: $apple;
      }
    }
    &.yellow .inside{
      color: $yellow;
      &:before{
        background: $yellow;
      }
    }
    &.red .inside{
      color: $faded-red;
      &:before{
        background: $faded-red;
      }
    }
  }
}
