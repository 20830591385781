.list-documents{
  @include clearfix;

  @include breakpoint(medium up){
    margin: 0px -15px;
    > li{
      float: left !important; ;
    }
  }

  > li{
    margin-bottom: 30px;
      @include breakpoint(xlarge up){
        @include grid-column(4, 30px);

        &:nth-child(3n+1){
          clear: both;
        }
      }

      @include breakpoint(large only){
        @include grid-column(6, 30px);
        &:nth-child(2n+1){
          clear: both;
        }
      }
      @include breakpoint(medium only){
        @include grid-column(6, 30px);
        &:nth-child(2n+1){
          clear: both;
        }
      }

      a{
        color: #fff;
        background: $mainColor;
        min-height: 65px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        text-align: left;
        color: #ffffff;
        border-radius: 3px;
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 60px;
        &:after{
          content: "";
          position: absolute;
          @include global-inButtonWhite;
          top: 22px;
          right: 17px;
        }

        &:hover{
          background-color: $black;
        }
      }

  }
}
