// Generated with Spriteowl (compiler version 1.3.1)
//
// Please define the location for your image source:
//
// $global-sheet-image: url("../sprites/global.png");
// $global-sheet-image-2x: url("../sprites/global@2x.png");
//
// --- Layers --------------------------------------------
//
// - global/
//   - inButtonGreen
//   - inButtonWhite
//   - logoutBlack
//   - logoutGreen
//   - inButtonBackGreen
//   - inButtonBackWhite
//   - closeWhite
//   - closeGreen
//   - closeBlack
//   - closeRed
//   - inButtonAddWhite
//   - inButtonAddGreeen
//   - refuser
//   - valider
//   - fieldLock
//   - fieldPerson
//   - houseBlack
//   - houseWhite
//   - houseGreen
//
$global-sheet-width: 159px !default;
$global-sheet-height: 148px !default;
$global-sheet-image: url("../sprites/global.png") !default;
$global-sheet-image-2x: url("../sprites/global@2x.png") !default;
$global-sheet: $global-sheet-width $global-sheet-height $global-sheet-image $global-sheet-image-2x global !default;

@mixin global-element() {
  background-image: $global-sheet-image;
  background-repeat: no-repeat;
  @media only screen and (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
    background-image: $global-sheet-image-2x;
    background-size: $global-sheet-width $global-sheet-height;
  }
}
%global-element {
  @include global-element();
}

$global-inButtonGreen-x: -67px !default;
$global-inButtonGreen-y: -101px !default;
$global-inButtonGreen-width: 20px !default;
$global-inButtonGreen-height: 22px !default;
$global-inButtonGreen: $global-inButtonGreen-x $global-inButtonGreen-y $global-inButtonGreen-width $global-inButtonGreen-height global-inButtonGreen !default;

@mixin global-inButtonGreen-width() {
  width: $global-inButtonGreen-width;
}
%global-inButtonGreen-width {
  @include global-inButtonGreen-width();
}
@mixin global-inButtonGreen-height() {
  height: $global-inButtonGreen-height;
}
%global-inButtonGreen-height {
  @include global-inButtonGreen-height();
}
@mixin global-inButtonGreen-size() {
  @include global-inButtonGreen-width();
  @include global-inButtonGreen-height();
}
%global-inButtonGreen-size {
  @include global-inButtonGreen-size();
}
@mixin global-inButtonGreen-position() {
  background-position: $global-inButtonGreen-x $global-inButtonGreen-y;
}
%global-inButtonGreen-position {
  @include global-inButtonGreen-position();
}
@mixin global-inButtonGreen-adjust() {
  @include global-inButtonGreen-size();
  @include global-inButtonGreen-position();
}
%global-inButtonGreen-adjust {
  @include global-inButtonGreen-adjust();
}
@mixin global-inButtonGreen() {
  @include global-inButtonGreen-adjust();
  @extend %global-element;
}
%global-inButtonGreen {
  @include global-inButtonGreen();
}

$global-inButtonWhite-x: -42px !default;
$global-inButtonWhite-y: -101px !default;
$global-inButtonWhite-width: 20px !default;
$global-inButtonWhite-height: 22px !default;
$global-inButtonWhite: $global-inButtonWhite-x $global-inButtonWhite-y $global-inButtonWhite-width $global-inButtonWhite-height global-inButtonWhite !default;

@mixin global-inButtonWhite-width() {
  width: $global-inButtonWhite-width;
}
%global-inButtonWhite-width {
  @include global-inButtonWhite-width();
}
@mixin global-inButtonWhite-height() {
  height: $global-inButtonWhite-height;
}
%global-inButtonWhite-height {
  @include global-inButtonWhite-height();
}
@mixin global-inButtonWhite-size() {
  @include global-inButtonWhite-width();
  @include global-inButtonWhite-height();
}
%global-inButtonWhite-size {
  @include global-inButtonWhite-size();
}
@mixin global-inButtonWhite-position() {
  background-position: $global-inButtonWhite-x $global-inButtonWhite-y;
}
%global-inButtonWhite-position {
  @include global-inButtonWhite-position();
}
@mixin global-inButtonWhite-adjust() {
  @include global-inButtonWhite-size();
  @include global-inButtonWhite-position();
}
%global-inButtonWhite-adjust {
  @include global-inButtonWhite-adjust();
}
@mixin global-inButtonWhite() {
  @include global-inButtonWhite-adjust();
  @extend %global-element;
}
%global-inButtonWhite {
  @include global-inButtonWhite();
}

$global-logoutBlack-x: -70px !default;
$global-logoutBlack-y: -36px !default;
$global-logoutBlack-width: 25px !default;
$global-logoutBlack-height: 18px !default;
$global-logoutBlack: $global-logoutBlack-x $global-logoutBlack-y $global-logoutBlack-width $global-logoutBlack-height global-logoutBlack !default;

@mixin global-logoutBlack-width() {
  width: $global-logoutBlack-width;
}
%global-logoutBlack-width {
  @include global-logoutBlack-width();
}
@mixin global-logoutBlack-height() {
  height: $global-logoutBlack-height;
}
%global-logoutBlack-height {
  @include global-logoutBlack-height();
}
@mixin global-logoutBlack-size() {
  @include global-logoutBlack-width();
  @include global-logoutBlack-height();
}
%global-logoutBlack-size {
  @include global-logoutBlack-size();
}
@mixin global-logoutBlack-position() {
  background-position: $global-logoutBlack-x $global-logoutBlack-y;
}
%global-logoutBlack-position {
  @include global-logoutBlack-position();
}
@mixin global-logoutBlack-adjust() {
  @include global-logoutBlack-size();
  @include global-logoutBlack-position();
}
%global-logoutBlack-adjust {
  @include global-logoutBlack-adjust();
}
@mixin global-logoutBlack() {
  @include global-logoutBlack-adjust();
  @extend %global-element;
}
%global-logoutBlack {
  @include global-logoutBlack();
}

$global-logoutGreen-x: -44px !default;
$global-logoutGreen-y: -36px !default;
$global-logoutGreen-width: 25px !default;
$global-logoutGreen-height: 18px !default;
$global-logoutGreen: $global-logoutGreen-x $global-logoutGreen-y $global-logoutGreen-width $global-logoutGreen-height global-logoutGreen !default;

@mixin global-logoutGreen-width() {
  width: $global-logoutGreen-width;
}
%global-logoutGreen-width {
  @include global-logoutGreen-width();
}
@mixin global-logoutGreen-height() {
  height: $global-logoutGreen-height;
}
%global-logoutGreen-height {
  @include global-logoutGreen-height();
}
@mixin global-logoutGreen-size() {
  @include global-logoutGreen-width();
  @include global-logoutGreen-height();
}
%global-logoutGreen-size {
  @include global-logoutGreen-size();
}
@mixin global-logoutGreen-position() {
  background-position: $global-logoutGreen-x $global-logoutGreen-y;
}
%global-logoutGreen-position {
  @include global-logoutGreen-position();
}
@mixin global-logoutGreen-adjust() {
  @include global-logoutGreen-size();
  @include global-logoutGreen-position();
}
%global-logoutGreen-adjust {
  @include global-logoutGreen-adjust();
}
@mixin global-logoutGreen() {
  @include global-logoutGreen-adjust();
  @extend %global-element;
}
%global-logoutGreen {
  @include global-logoutGreen();
}

$global-inButtonBackGreen-x: -21px !default;
$global-inButtonBackGreen-y: -104px !default;
$global-inButtonBackGreen-width: 14px !default;
$global-inButtonBackGreen-height: 22px !default;
$global-inButtonBackGreen: $global-inButtonBackGreen-x $global-inButtonBackGreen-y $global-inButtonBackGreen-width $global-inButtonBackGreen-height global-inButtonBackGreen !default;

@mixin global-inButtonBackGreen-width() {
  width: $global-inButtonBackGreen-width;
}
%global-inButtonBackGreen-width {
  @include global-inButtonBackGreen-width();
}
@mixin global-inButtonBackGreen-height() {
  height: $global-inButtonBackGreen-height;
}
%global-inButtonBackGreen-height {
  @include global-inButtonBackGreen-height();
}
@mixin global-inButtonBackGreen-size() {
  @include global-inButtonBackGreen-width();
  @include global-inButtonBackGreen-height();
}
%global-inButtonBackGreen-size {
  @include global-inButtonBackGreen-size();
}
@mixin global-inButtonBackGreen-position() {
  background-position: $global-inButtonBackGreen-x $global-inButtonBackGreen-y;
}
%global-inButtonBackGreen-position {
  @include global-inButtonBackGreen-position();
}
@mixin global-inButtonBackGreen-adjust() {
  @include global-inButtonBackGreen-size();
  @include global-inButtonBackGreen-position();
}
%global-inButtonBackGreen-adjust {
  @include global-inButtonBackGreen-adjust();
}
@mixin global-inButtonBackGreen() {
  @include global-inButtonBackGreen-adjust();
  @extend %global-element;
}
%global-inButtonBackGreen {
  @include global-inButtonBackGreen();
}

$global-inButtonBackWhite-x: 0 !default;
$global-inButtonBackWhite-y: -104px !default;
$global-inButtonBackWhite-width: 14px !default;
$global-inButtonBackWhite-height: 22px !default;
$global-inButtonBackWhite: $global-inButtonBackWhite-x $global-inButtonBackWhite-y $global-inButtonBackWhite-width $global-inButtonBackWhite-height global-inButtonBackWhite !default;

@mixin global-inButtonBackWhite-width() {
  width: $global-inButtonBackWhite-width;
}
%global-inButtonBackWhite-width {
  @include global-inButtonBackWhite-width();
}
@mixin global-inButtonBackWhite-height() {
  height: $global-inButtonBackWhite-height;
}
%global-inButtonBackWhite-height {
  @include global-inButtonBackWhite-height();
}
@mixin global-inButtonBackWhite-size() {
  @include global-inButtonBackWhite-width();
  @include global-inButtonBackWhite-height();
}
%global-inButtonBackWhite-size {
  @include global-inButtonBackWhite-size();
}
@mixin global-inButtonBackWhite-position() {
  background-position: $global-inButtonBackWhite-x $global-inButtonBackWhite-y;
}
%global-inButtonBackWhite-position {
  @include global-inButtonBackWhite-position();
}
@mixin global-inButtonBackWhite-adjust() {
  @include global-inButtonBackWhite-size();
  @include global-inButtonBackWhite-position();
}
%global-inButtonBackWhite-adjust {
  @include global-inButtonBackWhite-adjust();
}
@mixin global-inButtonBackWhite() {
  @include global-inButtonBackWhite-adjust();
  @extend %global-element;
}
%global-inButtonBackWhite {
  @include global-inButtonBackWhite();
}

$global-closeWhite-x: -137px !default;
$global-closeWhite-y: 0 !default;
$global-closeWhite-width: 22px !default;
$global-closeWhite-height: 22px !default;
$global-closeWhite: $global-closeWhite-x $global-closeWhite-y $global-closeWhite-width $global-closeWhite-height global-closeWhite !default;

@mixin global-closeWhite-width() {
  width: $global-closeWhite-width;
}
%global-closeWhite-width {
  @include global-closeWhite-width();
}
@mixin global-closeWhite-height() {
  height: $global-closeWhite-height;
}
%global-closeWhite-height {
  @include global-closeWhite-height();
}
@mixin global-closeWhite-size() {
  @include global-closeWhite-width();
  @include global-closeWhite-height();
}
%global-closeWhite-size {
  @include global-closeWhite-size();
}
@mixin global-closeWhite-position() {
  background-position: $global-closeWhite-x $global-closeWhite-y;
}
%global-closeWhite-position {
  @include global-closeWhite-position();
}
@mixin global-closeWhite-adjust() {
  @include global-closeWhite-size();
  @include global-closeWhite-position();
}
%global-closeWhite-adjust {
  @include global-closeWhite-adjust();
}
@mixin global-closeWhite() {
  @include global-closeWhite-adjust();
  @extend %global-element;
}
%global-closeWhite {
  @include global-closeWhite();
}

$global-closeGreen-x: -112px !default;
$global-closeGreen-y: 0 !default;
$global-closeGreen-width: 22px !default;
$global-closeGreen-height: 22px !default;
$global-closeGreen: $global-closeGreen-x $global-closeGreen-y $global-closeGreen-width $global-closeGreen-height global-closeGreen !default;

@mixin global-closeGreen-width() {
  width: $global-closeGreen-width;
}
%global-closeGreen-width {
  @include global-closeGreen-width();
}
@mixin global-closeGreen-height() {
  height: $global-closeGreen-height;
}
%global-closeGreen-height {
  @include global-closeGreen-height();
}
@mixin global-closeGreen-size() {
  @include global-closeGreen-width();
  @include global-closeGreen-height();
}
%global-closeGreen-size {
  @include global-closeGreen-size();
}
@mixin global-closeGreen-position() {
  background-position: $global-closeGreen-x $global-closeGreen-y;
}
%global-closeGreen-position {
  @include global-closeGreen-position();
}
@mixin global-closeGreen-adjust() {
  @include global-closeGreen-size();
  @include global-closeGreen-position();
}
%global-closeGreen-adjust {
  @include global-closeGreen-adjust();
}
@mixin global-closeGreen() {
  @include global-closeGreen-adjust();
  @extend %global-element;
}
%global-closeGreen {
  @include global-closeGreen();
}

$global-closeBlack-x: -86px !default;
$global-closeBlack-y: 0 !default;
$global-closeBlack-width: 22px !default;
$global-closeBlack-height: 22px !default;
$global-closeBlack: $global-closeBlack-x $global-closeBlack-y $global-closeBlack-width $global-closeBlack-height global-closeBlack !default;

@mixin global-closeBlack-width() {
  width: $global-closeBlack-width;
}
%global-closeBlack-width {
  @include global-closeBlack-width();
}
@mixin global-closeBlack-height() {
  height: $global-closeBlack-height;
}
%global-closeBlack-height {
  @include global-closeBlack-height();
}
@mixin global-closeBlack-size() {
  @include global-closeBlack-width();
  @include global-closeBlack-height();
}
%global-closeBlack-size {
  @include global-closeBlack-size();
}
@mixin global-closeBlack-position() {
  background-position: $global-closeBlack-x $global-closeBlack-y;
}
%global-closeBlack-position {
  @include global-closeBlack-position();
}
@mixin global-closeBlack-adjust() {
  @include global-closeBlack-size();
  @include global-closeBlack-position();
}
%global-closeBlack-adjust {
  @include global-closeBlack-adjust();
}
@mixin global-closeBlack() {
  @include global-closeBlack-adjust();
  @extend %global-element;
}
%global-closeBlack {
  @include global-closeBlack();
}

$global-closeRed-x: -56px !default;
$global-closeRed-y: 0 !default;
$global-closeRed-width: 22px !default;
$global-closeRed-height: 22px !default;
$global-closeRed: $global-closeRed-x $global-closeRed-y $global-closeRed-width $global-closeRed-height global-closeRed !default;

@mixin global-closeRed-width() {
  width: $global-closeRed-width;
}
%global-closeRed-width {
  @include global-closeRed-width();
}
@mixin global-closeRed-height() {
  height: $global-closeRed-height;
}
%global-closeRed-height {
  @include global-closeRed-height();
}
@mixin global-closeRed-size() {
  @include global-closeRed-width();
  @include global-closeRed-height();
}
%global-closeRed-size {
  @include global-closeRed-size();
}
@mixin global-closeRed-position() {
  background-position: $global-closeRed-x $global-closeRed-y;
}
%global-closeRed-position {
  @include global-closeRed-position();
}
@mixin global-closeRed-adjust() {
  @include global-closeRed-size();
  @include global-closeRed-position();
}
%global-closeRed-adjust {
  @include global-closeRed-adjust();
}
@mixin global-closeRed() {
  @include global-closeRed-adjust();
  @extend %global-element;
}
%global-closeRed {
  @include global-closeRed();
}

$global-inButtonAddWhite-x: -17px !default;
$global-inButtonAddWhite-y: -134px !default;
$global-inButtonAddWhite-width: 15px !default;
$global-inButtonAddWhite-height: 14px !default;
$global-inButtonAddWhite: $global-inButtonAddWhite-x $global-inButtonAddWhite-y $global-inButtonAddWhite-width $global-inButtonAddWhite-height global-inButtonAddWhite !default;

@mixin global-inButtonAddWhite-width() {
  width: $global-inButtonAddWhite-width;
}
%global-inButtonAddWhite-width {
  @include global-inButtonAddWhite-width();
}
@mixin global-inButtonAddWhite-height() {
  height: $global-inButtonAddWhite-height;
}
%global-inButtonAddWhite-height {
  @include global-inButtonAddWhite-height();
}
@mixin global-inButtonAddWhite-size() {
  @include global-inButtonAddWhite-width();
  @include global-inButtonAddWhite-height();
}
%global-inButtonAddWhite-size {
  @include global-inButtonAddWhite-size();
}
@mixin global-inButtonAddWhite-position() {
  background-position: $global-inButtonAddWhite-x $global-inButtonAddWhite-y;
}
%global-inButtonAddWhite-position {
  @include global-inButtonAddWhite-position();
}
@mixin global-inButtonAddWhite-adjust() {
  @include global-inButtonAddWhite-size();
  @include global-inButtonAddWhite-position();
}
%global-inButtonAddWhite-adjust {
  @include global-inButtonAddWhite-adjust();
}
@mixin global-inButtonAddWhite() {
  @include global-inButtonAddWhite-adjust();
  @extend %global-element;
}
%global-inButtonAddWhite {
  @include global-inButtonAddWhite();
}

$global-inButtonAddGreeen-x: -1px !default;
$global-inButtonAddGreeen-y: -134px !default;
$global-inButtonAddGreeen-width: 15px !default;
$global-inButtonAddGreeen-height: 14px !default;
$global-inButtonAddGreeen: $global-inButtonAddGreeen-x $global-inButtonAddGreeen-y $global-inButtonAddGreeen-width $global-inButtonAddGreeen-height global-inButtonAddGreeen !default;

@mixin global-inButtonAddGreeen-width() {
  width: $global-inButtonAddGreeen-width;
}
%global-inButtonAddGreeen-width {
  @include global-inButtonAddGreeen-width();
}
@mixin global-inButtonAddGreeen-height() {
  height: $global-inButtonAddGreeen-height;
}
%global-inButtonAddGreeen-height {
  @include global-inButtonAddGreeen-height();
}
@mixin global-inButtonAddGreeen-size() {
  @include global-inButtonAddGreeen-width();
  @include global-inButtonAddGreeen-height();
}
%global-inButtonAddGreeen-size {
  @include global-inButtonAddGreeen-size();
}
@mixin global-inButtonAddGreeen-position() {
  background-position: $global-inButtonAddGreeen-x $global-inButtonAddGreeen-y;
}
%global-inButtonAddGreeen-position {
  @include global-inButtonAddGreeen-position();
}
@mixin global-inButtonAddGreeen-adjust() {
  @include global-inButtonAddGreeen-size();
  @include global-inButtonAddGreeen-position();
}
%global-inButtonAddGreeen-adjust {
  @include global-inButtonAddGreeen-adjust();
}
@mixin global-inButtonAddGreeen() {
  @include global-inButtonAddGreeen-adjust();
  @extend %global-element;
}
%global-inButtonAddGreeen {
  @include global-inButtonAddGreeen();
}

$global-refuser-x: -38px !default;
$global-refuser-y: -62px !default;
$global-refuser-width: 32px !default;
$global-refuser-height: 32px !default;
$global-refuser: $global-refuser-x $global-refuser-y $global-refuser-width $global-refuser-height global-refuser !default;

@mixin global-refuser-width() {
  width: $global-refuser-width;
}
%global-refuser-width {
  @include global-refuser-width();
}
@mixin global-refuser-height() {
  height: $global-refuser-height;
}
%global-refuser-height {
  @include global-refuser-height();
}
@mixin global-refuser-size() {
  @include global-refuser-width();
  @include global-refuser-height();
}
%global-refuser-size {
  @include global-refuser-size();
}
@mixin global-refuser-position() {
  background-position: $global-refuser-x $global-refuser-y;
}
%global-refuser-position {
  @include global-refuser-position();
}
@mixin global-refuser-adjust() {
  @include global-refuser-size();
  @include global-refuser-position();
}
%global-refuser-adjust {
  @include global-refuser-adjust();
}
@mixin global-refuser() {
  @include global-refuser-adjust();
  @extend %global-element;
}
%global-refuser {
  @include global-refuser();
}

$global-valider-x: -1px !default;
$global-valider-y: -62px !default;
$global-valider-width: 32px !default;
$global-valider-height: 32px !default;
$global-valider: $global-valider-x $global-valider-y $global-valider-width $global-valider-height global-valider !default;

@mixin global-valider-width() {
  width: $global-valider-width;
}
%global-valider-width {
  @include global-valider-width();
}
@mixin global-valider-height() {
  height: $global-valider-height;
}
%global-valider-height {
  @include global-valider-height();
}
@mixin global-valider-size() {
  @include global-valider-width();
  @include global-valider-height();
}
%global-valider-size {
  @include global-valider-size();
}
@mixin global-valider-position() {
  background-position: $global-valider-x $global-valider-y;
}
%global-valider-position {
  @include global-valider-position();
}
@mixin global-valider-adjust() {
  @include global-valider-size();
  @include global-valider-position();
}
%global-valider-adjust {
  @include global-valider-adjust();
}
@mixin global-valider() {
  @include global-valider-adjust();
  @extend %global-element;
}
%global-valider {
  @include global-valider();
}

$global-fieldLock-x: -22px !default;
$global-fieldLock-y: -35px !default;
$global-fieldLock-width: 15px !default;
$global-fieldLock-height: 19px !default;
$global-fieldLock: $global-fieldLock-x $global-fieldLock-y $global-fieldLock-width $global-fieldLock-height global-fieldLock !default;

@mixin global-fieldLock-width() {
  width: $global-fieldLock-width;
}
%global-fieldLock-width {
  @include global-fieldLock-width();
}
@mixin global-fieldLock-height() {
  height: $global-fieldLock-height;
}
%global-fieldLock-height {
  @include global-fieldLock-height();
}
@mixin global-fieldLock-size() {
  @include global-fieldLock-width();
  @include global-fieldLock-height();
}
%global-fieldLock-size {
  @include global-fieldLock-size();
}
@mixin global-fieldLock-position() {
  background-position: $global-fieldLock-x $global-fieldLock-y;
}
%global-fieldLock-position {
  @include global-fieldLock-position();
}
@mixin global-fieldLock-adjust() {
  @include global-fieldLock-size();
  @include global-fieldLock-position();
}
%global-fieldLock-adjust {
  @include global-fieldLock-adjust();
}
@mixin global-fieldLock() {
  @include global-fieldLock-adjust();
  @extend %global-element;
}
%global-fieldLock {
  @include global-fieldLock();
}

$global-fieldPerson-x: 0 !default;
$global-fieldPerson-y: -35px !default;
$global-fieldPerson-width: 18px !default;
$global-fieldPerson-height: 19px !default;
$global-fieldPerson: $global-fieldPerson-x $global-fieldPerson-y $global-fieldPerson-width $global-fieldPerson-height global-fieldPerson !default;

@mixin global-fieldPerson-width() {
  width: $global-fieldPerson-width;
}
%global-fieldPerson-width {
  @include global-fieldPerson-width();
}
@mixin global-fieldPerson-height() {
  height: $global-fieldPerson-height;
}
%global-fieldPerson-height {
  @include global-fieldPerson-height();
}
@mixin global-fieldPerson-size() {
  @include global-fieldPerson-width();
  @include global-fieldPerson-height();
}
%global-fieldPerson-size {
  @include global-fieldPerson-size();
}
@mixin global-fieldPerson-position() {
  background-position: $global-fieldPerson-x $global-fieldPerson-y;
}
%global-fieldPerson-position {
  @include global-fieldPerson-position();
}
@mixin global-fieldPerson-adjust() {
  @include global-fieldPerson-size();
  @include global-fieldPerson-position();
}
%global-fieldPerson-adjust {
  @include global-fieldPerson-adjust();
}
@mixin global-fieldPerson() {
  @include global-fieldPerson-adjust();
  @extend %global-element;
}
%global-fieldPerson {
  @include global-fieldPerson();
}

$global-houseBlack-x: -26px !default;
$global-houseBlack-y: 0 !default;
$global-houseBlack-width: 23px !default;
$global-houseBlack-height: 23px !default;
$global-houseBlack: $global-houseBlack-x $global-houseBlack-y $global-houseBlack-width $global-houseBlack-height global-houseBlack !default;

@mixin global-houseBlack-width() {
  width: $global-houseBlack-width;
}
%global-houseBlack-width {
  @include global-houseBlack-width();
}
@mixin global-houseBlack-height() {
  height: $global-houseBlack-height;
}
%global-houseBlack-height {
  @include global-houseBlack-height();
}
@mixin global-houseBlack-size() {
  @include global-houseBlack-width();
  @include global-houseBlack-height();
}
%global-houseBlack-size {
  @include global-houseBlack-size();
}
@mixin global-houseBlack-position() {
  background-position: $global-houseBlack-x $global-houseBlack-y;
}
%global-houseBlack-position {
  @include global-houseBlack-position();
}
@mixin global-houseBlack-adjust() {
  @include global-houseBlack-size();
  @include global-houseBlack-position();
}
%global-houseBlack-adjust {
  @include global-houseBlack-adjust();
}
@mixin global-houseBlack() {
  @include global-houseBlack-adjust();
  @extend %global-element;
}
%global-houseBlack {
  @include global-houseBlack();
}

$global-houseWhite-x: -102px !default;
$global-houseWhite-y: -33px !default;
$global-houseWhite-width: 23px !default;
$global-houseWhite-height: 23px !default;
$global-houseWhite: $global-houseWhite-x $global-houseWhite-y $global-houseWhite-width $global-houseWhite-height global-houseWhite !default;

@mixin global-houseWhite-width() {
  width: $global-houseWhite-width;
}
%global-houseWhite-width {
  @include global-houseWhite-width();
}
@mixin global-houseWhite-height() {
  height: $global-houseWhite-height;
}
%global-houseWhite-height {
  @include global-houseWhite-height();
}
@mixin global-houseWhite-size() {
  @include global-houseWhite-width();
  @include global-houseWhite-height();
}
%global-houseWhite-size {
  @include global-houseWhite-size();
}
@mixin global-houseWhite-position() {
  background-position: $global-houseWhite-x $global-houseWhite-y;
}
%global-houseWhite-position {
  @include global-houseWhite-position();
}
@mixin global-houseWhite-adjust() {
  @include global-houseWhite-size();
  @include global-houseWhite-position();
}
%global-houseWhite-adjust {
  @include global-houseWhite-adjust();
}
@mixin global-houseWhite() {
  @include global-houseWhite-adjust();
  @extend %global-element;
}
%global-houseWhite {
  @include global-houseWhite();
}

$global-houseGreen-x: 0 !default;
$global-houseGreen-y: 0 !default;
$global-houseGreen-width: 23px !default;
$global-houseGreen-height: 23px !default;
$global-houseGreen: $global-houseGreen-x $global-houseGreen-y $global-houseGreen-width $global-houseGreen-height global-houseGreen !default;

@mixin global-houseGreen-width() {
  width: $global-houseGreen-width;
}
%global-houseGreen-width {
  @include global-houseGreen-width();
}
@mixin global-houseGreen-height() {
  height: $global-houseGreen-height;
}
%global-houseGreen-height {
  @include global-houseGreen-height();
}
@mixin global-houseGreen-size() {
  @include global-houseGreen-width();
  @include global-houseGreen-height();
}
%global-houseGreen-size {
  @include global-houseGreen-size();
}
@mixin global-houseGreen-position() {
  background-position: $global-houseGreen-x $global-houseGreen-y;
}
%global-houseGreen-position {
  @include global-houseGreen-position();
}
@mixin global-houseGreen-adjust() {
  @include global-houseGreen-size();
  @include global-houseGreen-position();
}
%global-houseGreen-adjust {
  @include global-houseGreen-adjust();
}
@mixin global-houseGreen() {
  @include global-houseGreen-adjust();
  @extend %global-element;
}
%global-houseGreen {
  @include global-houseGreen();
}

