.hamburger{
	display: block;
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 25px;
	height: 20px;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	box-shadow: none;
	border-radius: none;
	border: none;
	cursor: pointer;
	transition: background 0.3s;
	background: none;
	outline: none;
	display: none;



	span{
		display: block;
		position: relative;
		width: 100%;
		height: 2px;
		margin: 0px auto;
		background: $mainColor;
		transition: background 0s 0.3s;
		margin-top: 10px;

		&:before,
		&:after {
		  position: absolute;
		  display: block;
		  left: 0;
		  width: 100%;
		  height: 2px;
		  background-color: $mainColor;
		  content: "";
		  transition-duration: 0.3s, 0.3s;
			transition-delay: 0.3s, 0s;
		}

		&:before {
		  top: -7px;
		    transition-property: top, transform;
		}

		&:after {
		  bottom: -7px;
		   transition-property: bottom, transform;
		}
	}

	&.is-active{


		  span{
		  	background: none;


		  	&:before{
				 top: 0;
				transform: rotate(45deg);
				transition-delay: 0s, 0.3s;
					background-color: $mainColor;
		  	}

		  	&:after{
				bottom: 0;
				transform: rotate(-45deg);
				transition-delay: 0s, 0.3s;
					background-color: $mainColor;
		  	}
		  }
	}
}
