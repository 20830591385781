#landing_etudiant {
	background: url(../img/bg_etudiant.png) center no-repeat;
	background-size: cover;
	color: $realBlack;
	padding-bottom: 80px;
	min-height: calc(100vh - 40px);
	overflow: hidden;

	header {
		@include row();
		font-size: 0;
		line-height: 110px;

		@include breakpoint(medium down) {
			line-height: 1;
		}

		#header_left {
			@include grid-column(4, $gutters);

			@include breakpoint(medium down) {
				width: 100%;
				text-align: center;
				padding-top: 20px;
				padding-bottom: 40px;
			}

			img {
				width: 170px;
				height: auto;
				vertical-align: middle;
			}
		}

		#header_right {
			@include grid-column(8, $gutters);
			text-align: right;

			@include breakpoint(medium down) {
				width: 100%;
				text-align: center;
			}

			p {
				font-size: 16px;
				font-weight: 700;
				margin-right: 60px;
				display: inline-block;
				vertical-align: middle;

				@include breakpoint(medium down) {
					display: block;
					text-align: center;
					margin-right: 0;
					margin-bottom: 40px;
				}

				a {
					color: $black;

					&:hover {
						color: $purple;
					}
				}
			}

			a:not(.site_blanchisserie) {
				@include roundButton($purple, $realBlack);
				vertical-align: middle;
			}
		}
	}


	h1 {
		font-size: 40px;
		font-weight: 700;
		padding-top: 60px;
		padding-bottom: 50px;
		position: relative;
		margin-bottom: 55px;

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: -174px;
			height: 6px;
			width: 348px;
			background: $purple;
			border-radius: 3px;
		}

		@include breakpoint(small down) {
			font-size: 30px;
		}
	}

	h2 {
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 30px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			right: -240px;
			top: 0;
			bottom: 0;
			height: 6px;
			width: 200px;
			margin: auto;
			background: $purple;
			border-radius: 3px;
		}

		@include breakpoint(large down) {

			&:after {
				right: -140px;
			}
		}

		@include breakpoint(medium down) {
			font-size: 24px;
			margin-bottom: 50px;

			&:after {
				right: -100px;
			}
		}

		@include breakpoint(small down) {
			font-size: 20px;

			&:after {
				right: -185px;
			}
		}
	}

	.page_description {
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 90px;

		h2:after {
			content: none;
		}
	}

	.landing_etudiant_content {
		background: $realWhite;
		padding: 65px 130px;
		padding-bottom: 60px;
		border-radius: 10px;		
 		box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);

 		&.login {
 			padding-left: 0;
 			padding-right: 0;
 		}

 		@include breakpoint(large down) {
 			padding-left: 30px;
 			padding-right: 30px;
 		}

	    @include breakpoint(small down) {
	    	padding-left: 15px;
	    	padding-right: 15px;
	    }

	    .messages.error {
	    	margin-bottom: 30px;
	    	padding: 20px;
	    	background: $faded-red;
	    	color: $white;
	    	line-height: 1.4;
	    	font-weight: 700;
	    }
	}

	form {

		.form-item .form-select {
			background: url(../img/arrow_select_desktop.png) right 18px center no-repeat $realWhite;
			background-size: 10px 7px;
			cursor: pointer;
		}

		label,
		.fieldset-legend {
			display: block;
			font-size: 16px;
			margin-bottom: 8px;
		}

		.form-type-managed-file > label {
			margin-bottom: 8px;
		}

		.form-type-date-popup label {
			display: none;
		}

		.field-name-field-justificatif-de-domicile {

			input[type=file],
			input[type=submit],
			.messages.error,
			.file-size,
			.file-icon {
				display: none;
			}

			input[type=file] + label,
			.file a {
				@include roundButton($purple, $realBlack);
				font-weight: 400;
				font-size: 16px;
				width: 100%;
				height: 50px;
				line-height: 50px;
				padding-top: 0;
				padding-bottom: 0;
				text-align: center;
				cursor: pointer;
				@include transition(0.3s);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.form-type-date-popup {

			input {
				padding-right: 50px;
				background: url(../img/picto_calendar.png) right 20px center no-repeat;
				background-size: 19px 19px;
			}
		}

		@include breakpoint(medium up) {

			.select_taille.conditionnal label {
				height: 32px;
			}
		}

		.form-actions input[type=submit] {
			background: $purple;
			font-size: 20px;
			font-weight: 700;
			padding-left: 40px; 
			padding-right: 40px;
			width: auto;
		}

		&#user-login {
			font-size: 0;
			text-align: center;

			.form-item-name,
			.form-item-pass {
				display: inline-block;
				vertical-align: top;
				width: 25%;
				text-align: left;

				&:not(:first-child) {
					margin-left: 20px;
				}


				@include breakpoint(medium down) {
					width: 44%;

					&:not(:first-child) {
						margin-left: 4%;
					}
				}

				@include breakpoint(medium down) {
					width: 100%;
					padding: 0 15px;

					&:not(:first-child) {
						margin-left: 0;
					}
				}

				&:before {
					content: none;
				}

				.form-text {
					text-indent: 0;
					padding: 0 15px;
				}

				.form-required {
					display: none;
				}

				.field_link {
					text-align: left;
					margin-top: 10px;
					font-size: 12px;
					color: $realBlack;

					&:hover {
						color: $purple;
					}
				}
			}
		}
	}

	#etudiant_compte_top {
		line-height: 50px;
		@include row();
		margin-bottom: 65px;

		@include breakpoint(medium down) {
			line-height: 1.4;
		}

		p {
			font-size: 16px;

			strong {
				font-size: 20px;
				font-weight: 700;
			}
		}

		.third {
			@include grid-column(4, $gutters);

			@include breakpoint(medium down) {
				width: 100%;
				margin-bottom: 30px;
				text-align: center;
			}
		}

		.two_thirds {
			@include grid-column(8, $gutters);
			text-align: right;

			@include breakpoint(medium down) {
				width: 100%;
				text-align: center;
			}

			form {

				.form-wrapper:not(.field-name-field-justificatif-de-domicile) {
					display: none;
				}

				.field-name-field-justificatif-de-domicile {

					label,
					.file-widget {
						display: inline-block;
						vertical-align: middle;
						margin-bottom: 0;
						height: 50px;

						@include breakpoint(medium down) {
							width: 100%;
						}
					}

					label {
						margin-right: 30px;

						.form-required {
							display: none;
						}
					}

					.description {
						display: none;
					}
				}
			}
		}

		.full {
			@include grid-column(12, $gutters);
		}
	}

	.etudiant_texte {

		p {
			line-height: 30px;
			font-size: 16px;

			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}
}

footer.etudiant {
	background: $purple;
	text-align: center;
	line-height: 40px;
	color: $realWhite;
	font-size: 12px;
	font-weight: 600;

	@include breakpoint(small down) { 
		line-height: 1.4;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	a {
		color: $realWhite;

		&:hover {
			color: $black;
		}
	}
}