#sidebar{
  #sidebar_top{
    background: $black-four;
    padding: 17px;
    text-align: center;

    img{
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }

  #sidebar_content{
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    padding-bottom: 170px;
    overflow: auto;

    @media screen and (max-height: 730px){
      padding-top: 30px;
    }

    #sidebar_content_header{
      margin-bottom: 60px;
      text-align: center;

      @media screen and (max-height: 730px){
      margin-bottom: 40px;

      }

      .img{
        margin-bottom: 30px;
        @media screen and (max-height: 600px){
          width: 45px;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          margin-right: 10px;
        }
        img{
          display: block;
          border-radius: 50%;
          max-width: 100%;
          height: auto;
          margin: 0px auto;
        }
      }

      h3{
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 20px;
        @media screen and (max-height: 600px){
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
        }
      }
      h4{
        font-size: 16px;
        color: $greyish;

          @media screen and (max-height: 600px){
            display: none; 
          }
      }
    }

    #sidebar_content_nav{
      padding-left: 55px;

      .menu{
        > li{
          &:not(:last-child){
            margin-bottom: 40px;
          }

          a{
            display: block;
            padding-left: 40px;
            position: relative;
            font-size: 16px;
            color: $warm-grey;

            &:hover, &.active-trail{
            	color: $aquamarine;
            }

            &:after{
              position: absolute;
              content: "";
              top: 0;
              left: 0;
            }
          }

          &.mes-commandes, &.commandes-en-cours{
            a{
              height: 19px; line-height: 19px;
              &:after{            @include menu-commandesGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-commandesGreen;  }}
            }
          }
          &.passer-commande{
            a{
              height: 20px; line-height: 20px;
              &:after{            @include menu-passerCommandeGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-passerCommandeGreen;  }}
            }
          }
          &.mes-reclamations, &.reclamations{
            a{
              height: 19px; line-height: 19px;
              &:after{            @include menu-reclamationsGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-reclamationsGreen;  }}
            }
          }
          &.objets-perdus{
            a{
              height: 26px; line-height: 26px;
              &:after{            @include menu-perduGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-perduGreen;  }}
            }
          }
          //ajouter
          &.commandes-a-valider{
            a{
              height: 20px; line-height: 20px;
              &:after{            @include menu-commandeValiderGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-commandeValiderGreen;  }}
            }
          }
          /**/
          &.commandes-archivees{
            a{
              height: 22px; line-height: 22px;
              &:after{            @include menu-commandeArchiverGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-commandeArchiverGreen;  }}
            }
          }
          &.mes-informations{
            a{
              height: 21px; line-height: 21px;
              &:after{            @include menu-informationsGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-informationsGreen;  }}
            }
          }
          &.documents{
            a{
              height: 21px; line-height: 21px;
              &:after{            @include menu-docGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-docGreen;  }}
            }
          }
          &.questionnaire{
            a{
              height: 12px; line-height: 12px;
              &:after{            @include menu-satisfactionGrey;   }
              &:hover, &.active-trail{ &:after{   @include menu-satisfactionGreen;  }}
            }
          }

        }
      }
    }
  }

  #sidebar_footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px; text-align: center;
    background:  $black-two;

    .menu{
      > li{
        display: inline;

        &:not(:last-child){
          &:after{
            content: " - ";
            color: $warm-grey;
          }
        }

        a{
          font-size: 16px;
          color: $warm-grey;

          &:hover{
            color: #fff;
          }
        }
      }
    }
  }
}



#sidebar_overlay{

  @media screen and (min-width: 390px) {

      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 7777;
      display: none;

  }

    @media screen and (max-width: 389px) {
      position: fixed;
      top: 9px;
      right: 11px;
      z-index: 9999;
      display: none;

      &:after{
        position: relative;
      }
    }



  &:after{
    content: "";
    @include global-closeWhite;
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
  }
}
