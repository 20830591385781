html, body{
  height: 100%;
}


#dashboard{
  position: relative;
  height: 100%;
  padding-left: 348px;

  @include breakpoint(medium down){
    padding-left: 0;
    padding-top: 80px;
  }


  #sidebar{
    width: 348px;
    position: fixed;
    max-width: 100%;

      @media screen and (max-width: 389px) {
        width: 100%;
      }
    height: 100%;
    left: 0;
    top: 0;
  	background-color: $black-two;

    z-index: 8888;

    &.is-active{
      left: 0;
    }

    @include breakpoint(medium down){
      left: -389px;
          @include transition(0.4s);
    }

  }

  @include breakpoint(large up){
    #page, #header{
      position: relative;
      padding-left: 35px;
      padding-right: 35px;

      .container{
        margin: 0;
      }
    }
  }
}
