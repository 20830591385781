form {

  input:not[type=checkbox],
  textarea,
  select,
  button {
    appearance: none;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    height: 1px;
    width: 1px;

    &.error + label:before {
      border-color: $faded-red !important;
    }
  }

  legend  {
    color: $greyish-brown;
  }

  .field_item {
    @include grid-column(12, $gutters);
    
    &:last-child:not(:first-child) {
      float: left;
    }

    &.third {
      width: calc(100% / 3);

      @include breakpoint(medium down) {
        width: 50%;
      }

      @include breakpoint(small down) {
        width: 100%;
      }
    }

    &.two_fifth {
      width: 40%;

      @include breakpoint(small down) {
        width: 100%;
      }
    }

    &.three_fifth {
      width: 60%;

      @include breakpoint(small down) {
        width: 100%;
      }
    }

    &.clear {
      clear: left;
    }

    &.has_legend {

      .form-required {
        color: $faded-red;
      }

      .form-item {
        margin-bottom: 0 !important;
      }

      .legend {
        font-size: 14px;
        font-style: italic;
        color: $greyish-brown;
        margin-bottom: 20px;

        .red {
          color: $faded-red;
        }
      }
    }

    &.negative_margin label {

      @include breakpoint(medium up) {
        margin-top: -16px;
      }
    }

    &.visuel_carte {

      .form-radios {
        @include row();

        .form-type-radio {
          @include grid-column(4, $gutters);

          @include breakpoint(medium down) {
            width: 100%;
          }

          input {
            display: none;

            &+ label {
              font-size: 0 !important;
              margin-bottom: 0 !important;
              height: 200px;
              cursor: pointer;
            }

            &[value="sabatier"] + label {
              background: url(../img/carte_ps.png) center no-repeat;
            }

            &[value="prefms"] + label {
              background: url(../img/carte_prefms.png) center no-repeat;
            }

            &[value="jaures"] + label {
              background: url(../img/carte_jj.png) center no-repeat;
            }

            &:checked + label {
              position: relative;

              &:before {
                content: "";
                position: absolute;
                width: 30px;
                height: 30px;
                background: url(../img/check.png) center no-repeat;
                background-size: 30px auto;
                top: 15px;
                left: 15px;
              }
            }
          }
        }
      }
    }

    &.lieu_date {

      fieldset {
        width: 100% !important;
        padding: 0 !important;
      }

      table + .clearfix {
        text-align: right;

        input {
          border-color: $purple !important;
          color: $purple !important;
          display: inline-block !important;

          &:hover {
            color: $white !important;
            background: $purple !important;
          }
        }
      }

      .field-type-list-text:first-child label {

        @include breakpoint(medium up) {
          margin-top: -16px;
        }
      }

      @include breakpoint(small down) {
        .cancel {
          position: static !important;
          margin-bottom: 30px;
          margin-left: auto;
          display: block;

        }
      }
    }

    &.accept_rgpd {

      input[type=checkbox] {
        //display: none;

        &+ label {
          padding-left: 35px;
          position: relative;
          cursor: pointer;
          line-height: 26px;

          &:before{
            content: "";
            width: 26px;
            height: 26px;
            border: solid 1px $greyish;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;

          }

          &:after{
            content: "";
            width: 16px;
            height: 16px;
            background: $purple;
            border-radius: 3px;
            position: absolute;
            top: 5px;
            left: 5px;
            opacity: 0;
            @include transition(0.3s);
          }
        }

        &:checked +label{
          &:after{
            opacity: 1;
          }
        }
      }
    }
  }
}

#user-register-form {

  .field-type-list-boolean{

    > .form-item{
      margin-bottom: 10px;
      input{
        //display: none;
      }

      label{
        height: 15px;
        line-height: 15px;
        padding-left: 25px;
        position: relative;
        display: block;
        font-size: 13px;
        cursor: pointer;

        a {
          color: $aquamarine;
        }

        &:before{
          content: "";
          width: 15px;
          height: 15px;
          border: solid 1px $greyish;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;

        }

        &:after{
          content: "";
          width: 11px;
          height: 11px;
          background: $mainColor;
          position: absolute;
          top: 2px;
          left: 2px;
          opacity: 0;
          @include transition(0.3s);
        }
      }

      input:checked +label{
        &:after{
          opacity: 1;
        }
      }
    }
  }
}

#user-login, #user-register-form, .page-etudiant-register .landing_etudiant_content > form, #user-pass, .content_informations,
form.node-commande-form, #perte-node-form,
#reclamation-node-form, #user-profile-form{

  &.margin{
    margin-bottom: 40px;
  }

  .fieldset{
    &:not(:last-child){
      margin-bottom: 40px;
    }
  }

  h3{
    @include th-text;
    margin-bottom: 30px;
  }

  label, .label{
    @include label-text;
    display: block;
    margin-bottom: 9px;
  }

  .champ_libre {
    display: none;
  }

  .form-item{
    margin-bottom: 20px;

    .description{
      display: none;
    }

    .field_link{
      display: block;
      margin-top: 20px;
      font-size: 16px;
    	text-align: right;
    	color: #9a9a9a;
      &:hover{
        color: $aquamarine;
      }
    }

    .form-text, .form-select, .form-textarea{
      height: 50px;
    	border-radius: 10px;
    	border: solid 1px $white-two;
      width: 100%;
      text-indent: 20px;
    	@include label-text;
      @include input-placeholder {
        	@include label-text;
      }

      &.non_editable{
        line-height: 50px;

        a{
          color: $greyish-brown;
          pointer-events: none;
        }
      }

      outline: none;

      &:focus{
        border-color: $greyish;
        @include input-placeholder {
          color: #9a9a9a;
        }
      }

      &.error{
        border-color: $faded-red;
      }

      &.valid{
        border-color: $aquamarine;
      }
    }

    .form-textarea{
      height: 150px;
      text-indent: 0;
      padding: 20px;

        &.non_editable{
        height: auto;
        line-height: 1.25;
        }
    }

    .form-checkboxes{

      > .form-item{
        margin-bottom: 10px;
        input{
          //display: none;
        }

        label{
          height: 15px;
          line-height: 15px;
          padding-left: 25px;
          position: relative;
          display: block;
          font-size: 13px;
          cursor: pointer;

          &:before{
            content: "";
            width: 15px;
            height: 15px;
            border: solid 1px $greyish;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;

          }

          &:after{
            content: "";
            width: 11px;
            height: 11px;
            background: $mainColor;
            position: absolute;
            top: 2px;
            left: 2px;
            opacity: 0;
            @include transition(0.3s);
          }
        }

        input:checked +label{
          &:after{
            opacity: 1;
          }
        }
      }
    }
  }

  .downloadFile{
     @include buttonRoundedBorder($mainColor, #fff, 300px);
  }
   .link_download{
     


       display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
       height: 50px;
       width: 290px ;
       max-width: 100%;
       position: relative;
       background: #fff;
       border: solid 1px $mainColor;
        border-radius: 10px;
        color: $mainColor; 

          line-height: 50px;
       text-transform: uppercase;
        text-align: center;
        font-size: 16px;

        &:hover{
          background: $mainColor;
          &:after{
            color: #fff;
          }
         
        }

      
     
   }

  #edit-field-piece-jointe{
    legend, label{
      display: none;
    }
  }

  #edit-field-date{
    label{
      display: none;
    }

    legend{
      @include label-text;
      margin-bottom: 7px;
      display: block;
    }
  }

  .form-item-profile-etudiant-field-justificatif-de-domicile-und-0{
    > label{
      /*&:after{
        content: ' (- de 3 mois)'; 
        font-weight: bold; 
        font-size: 17px;
      }*/
    }
  }

  .field-type-field-collection{
    .tabledrag-toggle-weight-wrapper, table.sticky-header, .messages{
      display: none !important; ;
    }

    .field-add-more-submit{
      @include buttonRoundedBorder($mainColor, #fff, 215px);
    }

    table.field-multiple-table{
      display: block;
      tbody, tr, td{
        display:block;
      }
      thead, .field-multiple-drag, .tabledrag-hide{
        display: none !important;
      }

      tr{
        position: relative;
        @include clearfix;

        .cancel{
          font-size: 0;
          background-color: transparent;
          border: none;
          padding: 0;
          @include global-closeRed;
          position: absolute;
          right: -40px;
          bottom: 35px;
          @include transition(0s);
          cursor: pointer;
          &:hover{
            @include global-closeBlack;
          }
        }
      }
    }
  }

  .field_row, .field-multiple-table, #edit-field-password {
    @include clearfix;
    @include breakpoint(medium up){
      margin: 0px -15px;
    }
    &.col-3, &.sticky-enabled, .form-item-pass, .form-item-mail-container{
      > div, td .form-wrapper{

          @include breakpoint(large up){
              @include grid-column(4, 30px);
              float: left !important;
          }
          @include breakpoint(medium only){
              @include grid-column(6, 30px);
              float: left !important;
          }

          @media print{
              @include grid-column(6, 30px);
              float: left !important;
          }


      }
    }
  }


  .form-actions{
    margin-top: 35px;
    .form-submit{
      @include buttonRounded($aquamarine, $black-three, 215px);
      margin: 0px auto;
    }
  }
}


#user-login, .page-user-login #user-register-form, #user-pass, .content_informations,
form.node-commande-form, #perte-node-form,
#reclamation-node-form, #user-profile-form{

  /* FILE UPLOAD */
  .field-widget-file-generic, .field-widget-image-image{
    .tabledrag-toggle-weight-wrapper{
      display: none;

    }

    td{
      margin: 0 !important;
    }

    .ajax-new-content, tr.draggable{
      margin: 0px !important;
      clear: both;
      @include clearfix;


      td:first-child{
        float: left;
        width: 385px;
        max-width: 100%;
      }

      td:last-child{
        float: right;

        padding-top: 10px;
      }
    }

    table{
      width: 560px !important;
      max-width: 100% !important;
      margin-bottom: 30px;

      @include breakpoint(medium  down){
        width: 100%;
      }


      thead, .tabledrag-handle{
        display: none;
      }

      td{

        padding: 10px 0;
        vertical-align: middle;

        .file-widget{

          img, .file-size{
            display: none !important;
          }

          a{
            font-size: 16px;
            color: $black;
            display: block;
            height: 35px;
            line-height: 35px;

            pointer-events: none;
          }
        }

        .image-widget{
           .file-size{
            display: none;
           }

           .image-preview{
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
           }

           .image-widget-data{

            display: inline-block;
            vertical-align: middle;

            img{
              display: none;
            }

            a{
            font-size: 16px;
            color: $black;
            display: block;
            height: 35px;
            line-height: 35px;
            pointer-events: none;
            }
          }
        }

        .form-submit{
          display: block;
          float: right;
          border: none;
          background: $faded-red;
          height: 35px;
          line-height: 35px;

          padding: 0px;
          color: #fff;
          font-weight: $fwMedium;
          text-align: center;
          background: $faded-red;
          border-radius: 3px;
          width: 175px;
          font-size: 14px;
        }
      }


    }
  }

  .form-type-managed-file {
    > label{
      margin-bottom: 15px;
      display: block;
    }

    .filename{
      text-indent: 15px;
      line-height: 50px;
      display: block;
    }

    .form-managed-file{

      width: 360px;
      height: 50px;
      max-width: 100%;
      position: relative;
      background: #fff;
      border: solid 1px $mainColor;
      border-radius: 10px;

      &:after{
        content: "Ajouter une pièce jointe";
        color: $mainColor;
        position: absolute;
        width: 100%;
        height: 50px;
        line-height: 50px;
        left: 0;
        top: 0;
        text-align: center;
        font-size: 16px;
        pointer-events: none;

      }

      &:before{
          content: "";
          @include global-inButtonAddGreeen;
          position: absolute;
          top: 19px;
          right: 65px;
          pointer-events: none;
      }
      @include breakpoint(medium  down){
        width: 100%;
      }

      &:hover{
        background: $mainColor;

        &:after{
          color: #fff;
        }
        &:before{
          @include global-inButtonAddWhite;
        }
      }

      .form-file{
        opacity: 0;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        cursor: pointer;
        z-index: 55;
      }


      .form-submit{
        opacity: 0;
      }
    }
  }
}


#user-login{
  .form-item-name, .form-item-pass{
    position: relative;

      .form-text{
        text-indent: 60px;

      }

      &:before{
        content: "";
        position: absolute;
      }
  }
  .form-item-name{
    &:before{
      @include global-fieldPerson;
      top: 16px;
      left: 16px;
    }
  }
  .form-item-pass{
    &:before{
      @include global-fieldLock;
      top: 16px;
      left: 17px;
    }
  }
}

#edit-field-services{
  @include clearfix;
  .form-type-checkboxes {
    > label{
      margin-bottom: 15px;
    }
  }
  .form-checkboxes{
    margin: 0px -10px;

    > .form-item{

      @include breakpoint(xlarge up){
        @include grid-column(2, 20px);
      }
      @include breakpoint(large only){
        @include grid-column(3, 20px);
      }
      @include breakpoint(medium only){
        @include grid-column(4, 20px);
      }
      @include breakpoint(small down){
        @include grid-column(6, 20px);
      }
          float: left !important;
    }
  }
}


.page-user-register #user-register-form{
  #edit-field-services{
    .form-type-checkboxes{
      > label{
        display: block;
        margin-bottom: 15px;
      }

      .form-checkboxes{
      margin: 0px -10px;
      @include clearfix;

        > .form-item{
          @include breakpoint(medium up){
            @include grid-column(4, 20px);
          }
          @include breakpoint(small down){
            @include grid-column(6, 20px);
          }

        }
      }
    }
  }

  #edit-field-etablissement{
    label{
      display: none;
    }
  }

  @include breakpoint(large up){
    #edit-field-nom, #edit-field-grade-ou-fonction, #edit-account .form-item-name{
      width: 48%;
      float: left;
    }

    #edit-field-prenom, #edit-field-code-ua{
      float: right;
      width: 48%;
    }
  }
}

form.node-commande-form, #perte-node-form, #reclamation-node-form, #user-profile-form{
  .form-actions{
    .form-submit{
      float: right;
    }
  }
}

.page-reclamations,
.page-objets-perdus,
.page-commande-a-valider,
.page-commande-en-cours,
.page-commande-archivees {
  .views-exposed-form {
    font-size: 0;
    margin-bottom: 50px;

    > div > .views-exposed-widget {
      display: inline-block;
      vertical-align: bottom;
      max-width: 220px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      label {
        @include label-text;
        margin-bottom: 7px;
        display: block;
      }

      input,
      select {
        height: 50px;
        border-radius: 10px;
        border: solid 1px $white-two;
        width: 100%;
        text-indent: 20px;
        margin-bottom: 15px;
        @include label-text;
        @include input-placeholder{
            @include label-text;
        }
      }

      input[type=submit] {
        @include buttonRoundedBorder($mainColor, #fff, 215px);
        width: auto;
        text-indent: 0;
      }
    }
  }
}

.node-type-reclamation .field-name-field-reponse + .form-actions {
  display: none;
}

form.node-form {

  .field-type-list-boolean {

      > .form-item > input[type=checkbox] {
        display: none;

        &+ label {
          display: block !important;
          padding-left: 35px;
          position: relative;
          cursor: pointer;
          line-height: 26px;

          a {
            color: $aquamarine;
          }

          &:before{
            content: "";
            width: 26px;
            height: 26px;
            border: solid 1px $greyish;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;

          }

          &:after{
            content: "";
            width: 16px;
            height: 16px;
            background: $aquamarine;
            border-radius: 3px;
            position: absolute;
            top: 5px;
            left: 5px;
            opacity: 0;
            @include transition(0.3s);
          }
        }

        &:checked + label {
          &:after{
            opacity: 1;
          }
        }
      }
  }
}

// Modification formulaire passer une commande
form.node-commande-form .field-multiple-table.sticky-enabled td .form-wrapper {
  width: 25%;
}