
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}

/*Transitions*/

a,
input {
	@include transition(0.2s);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

input:not[type=checkbox], textarea{
	appearance: none;
	border-radius: 0;
}


html {
   -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
	margin: 0 !important;
	color: $black;
	font-family: $ffP;
	background: #fff;

	&.no-scroll{
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		position: fixed;
		width: 100%;
	}

}

.element-invisible{
	display: none !important;
}

a, input, button{
	outline: none;
}

#admin-menu{
  z-index: 9999;
}
