@media print {	
	
	.node-type-commande {

		#dashboard {
			padding-left: 0;

			#sidebar,
			#header,
			#content_top_left a,
			#content_top_right {
				display: none;
			}

			#content_top {
				padding-bottom: 10px;
			}

			#content > div {
				page-break-inside: avoid;
			}

			.content_informations {

				&.margin {
					margin-bottom: 10px;
				}

				h3 {
					font-size: 16px;
					margin-bottom: 15px;
				}

				.label,
				label {
					font-size: 14px;
				}

				.form-item {
					width: calc(100% / 3);

					.form-text  {
						height: 30px;
						line-height: 30px;
						font-size: 12px;
						text-indent: 10px;
						white-space: nowrap;
					}
				}

				.champ_libre {
					display: block;
					width: 100%;

					.form-item {
						width: 100%;
					}

					.form-text  {
						height: 100px;
					}
				}

				.field_row:first-of-type .form-item:last-child {
					font-weight: 700;
				}
			}

			.views-table {
				margin-bottom: 20px;

				tbody tr td {
					padding: 15px 0;

					ul > li:not(:last-child) {
						margin-bottom: 5px;
					}
				}

				.views-field-created,
				.views-field-view-3,
				.views-field-ops {
					display: none;
				}
			}
		}
	}
}