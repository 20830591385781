ul.pager{
	@include clearfix;

	position: relative;
	background: #ecedf2;
	text-align: center;
	margin-top: 25px;


			> li{



			&.pager-item, &.pager-current, &.pager-ellipsis{
				display: inline-block;

				height: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 14px;
				color: #fff;


				&:not(:last-child){
					margin-right: 25px;
				}

			}

			 &.pager-current{

				color: #3d3d3d;
				font-weight: $fwSemiBold;
			 }

			&.pager-item{
				a{
					display: block;

					color: $mainColor;
					text-decoration: underline;

					&:hover{
						color: $secondColor;

					}
				}
			}

			&.pager-last, &.pager-first{
				display: none !important;
			}

			&.pager-next, &.pager-previous{
				position: absolute;
				top: 0;



				a{
					display: block;
					height: 50px;
					line-height: 50px;
					font-size: 14px;
					color: #3d3d3d;
					position: relative;
					text-transform: capitalize;

					@include breakpoint(small  down){
						font-size: 0;
					}

					&:hover{
					 color: $secondColor;
					}


				}
			}

			&.pager-next{
				right: 25px;

				a{
        font-size: 16px;

					&:after{

						@include font($zmdi-var-long-arrow-right);
						color: $secondColor;
						margin-left: 4px;
						font-size: 14px;
					}
				}
			}

			&.pager-previous{
				left: 25px;

				a{
          font-size: 16px;

					&:before{

						@include font($zmdi-var-long-arrow-left);
						color: $secondColor;
						margin-right: 4px;
						font-size: 14px;
					}
				}
			}
		}
}
