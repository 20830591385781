.rte{

  h2{
    @include h-2-rte;
    margin-bottom: 30px;
  }

  h3{
    @include h-3-rte;
    margin-bottom: 30px;
  }

  p{
    @include p-rte;
    margin-bottom: 30px;
  }

  ul{
    margin-bottom: 30px;
    li{
      @include p-rte;
      padding-left: 40px;
      position: relative;

      &:before{
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $mainColor;
        position: absolute;
        top: 9px;
        left: 0;
      }

      &:not(:last-child){
        margin-bottom: 7px;
      }
    }
  }

}
