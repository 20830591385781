@mixin roundButton($bgColor, $bgHover) {
	display: inline-block;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 700;
	color: $realWhite;
	background: $bgColor;
	padding: 13px 40px;
	border-radius: 50px;

	&:hover {
		background: $bgHover;
	}
}