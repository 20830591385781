// Generated with Spriteowl (compiler version 1.3.1)
//
// Please define the location for your image source:
//
// $menu-sheet-image: url("../sprites/menu.png");
// $menu-sheet-image-2x: url("../sprites/menu@2x.png");
//
// --- Layers --------------------------------------------
//
// - menu/
//   - commandeArchiverGreen
//   - commandeValiderGreen
//   - commandeArchiverGrey
//   - commandeValiderGrey
//   - satisfactionGreen
//   - satisfactionGrey
//   - perduGreen
//   - perduGrey
//   - reclamationsGreen
//   - reclamationsGrey
//   - informationsGrey
//   - informationsGreen
//   - passerCommandeGreen
//   - passerCommandeGrey
//   - commandesGreen
//   - commandesGrey
//   - docGreen
//   - docGrey
//
$menu-sheet-width: 58px !default;
$menu-sheet-height: 219px !default;
$menu-sheet-image: url("../sprites/menu.png") !default;
$menu-sheet-image-2x: url("../sprites/menu@2x.png") !default;
$menu-sheet: $menu-sheet-width $menu-sheet-height $menu-sheet-image $menu-sheet-image-2x menu !default;

@mixin menu-element() {
  background-image: $menu-sheet-image;
  background-repeat: no-repeat;
  @media only screen and (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
    background-image: $menu-sheet-image-2x;
    background-size: $menu-sheet-width $menu-sheet-height;
  }
}
%menu-element {
  @include menu-element();
}

$menu-commandeArchiverGreen-x: -36px !default;
$menu-commandeArchiverGreen-y: -197px !default;
$menu-commandeArchiverGreen-width: 22px !default;
$menu-commandeArchiverGreen-height: 22px !default;
$menu-commandeArchiverGreen: $menu-commandeArchiverGreen-x $menu-commandeArchiverGreen-y $menu-commandeArchiverGreen-width $menu-commandeArchiverGreen-height menu-commandeArchiverGreen !default;

@mixin menu-commandeArchiverGreen-width() {
  width: $menu-commandeArchiverGreen-width;
}
%menu-commandeArchiverGreen-width {
  @include menu-commandeArchiverGreen-width();
}
@mixin menu-commandeArchiverGreen-height() {
  height: $menu-commandeArchiverGreen-height;
}
%menu-commandeArchiverGreen-height {
  @include menu-commandeArchiverGreen-height();
}
@mixin menu-commandeArchiverGreen-size() {
  @include menu-commandeArchiverGreen-width();
  @include menu-commandeArchiverGreen-height();
}
%menu-commandeArchiverGreen-size {
  @include menu-commandeArchiverGreen-size();
}
@mixin menu-commandeArchiverGreen-position() {
  background-position: $menu-commandeArchiverGreen-x $menu-commandeArchiverGreen-y;
}
%menu-commandeArchiverGreen-position {
  @include menu-commandeArchiverGreen-position();
}
@mixin menu-commandeArchiverGreen-adjust() {
  @include menu-commandeArchiverGreen-size();
  @include menu-commandeArchiverGreen-position();
}
%menu-commandeArchiverGreen-adjust {
  @include menu-commandeArchiverGreen-adjust();
}
@mixin menu-commandeArchiverGreen() {
  @include menu-commandeArchiverGreen-adjust();
  @extend %menu-element;
}
%menu-commandeArchiverGreen {
  @include menu-commandeArchiverGreen();
}

$menu-commandeValiderGreen-x: -38px !default;
$menu-commandeValiderGreen-y: -172px !default;
$menu-commandeValiderGreen-width: 18px !default;
$menu-commandeValiderGreen-height: 20px !default;
$menu-commandeValiderGreen: $menu-commandeValiderGreen-x $menu-commandeValiderGreen-y $menu-commandeValiderGreen-width $menu-commandeValiderGreen-height menu-commandeValiderGreen !default;

@mixin menu-commandeValiderGreen-width() {
  width: $menu-commandeValiderGreen-width;
}
%menu-commandeValiderGreen-width {
  @include menu-commandeValiderGreen-width();
}
@mixin menu-commandeValiderGreen-height() {
  height: $menu-commandeValiderGreen-height;
}
%menu-commandeValiderGreen-height {
  @include menu-commandeValiderGreen-height();
}
@mixin menu-commandeValiderGreen-size() {
  @include menu-commandeValiderGreen-width();
  @include menu-commandeValiderGreen-height();
}
%menu-commandeValiderGreen-size {
  @include menu-commandeValiderGreen-size();
}
@mixin menu-commandeValiderGreen-position() {
  background-position: $menu-commandeValiderGreen-x $menu-commandeValiderGreen-y;
}
%menu-commandeValiderGreen-position {
  @include menu-commandeValiderGreen-position();
}
@mixin menu-commandeValiderGreen-adjust() {
  @include menu-commandeValiderGreen-size();
  @include menu-commandeValiderGreen-position();
}
%menu-commandeValiderGreen-adjust {
  @include menu-commandeValiderGreen-adjust();
}
@mixin menu-commandeValiderGreen() {
  @include menu-commandeValiderGreen-adjust();
  @extend %menu-element;
}
%menu-commandeValiderGreen {
  @include menu-commandeValiderGreen();
}

$menu-commandeArchiverGrey-x: 0 !default;
$menu-commandeArchiverGrey-y: -197px !default;
$menu-commandeArchiverGrey-width: 23px !default;
$menu-commandeArchiverGrey-height: 22px !default;
$menu-commandeArchiverGrey: $menu-commandeArchiverGrey-x $menu-commandeArchiverGrey-y $menu-commandeArchiverGrey-width $menu-commandeArchiverGrey-height menu-commandeArchiverGrey !default;

@mixin menu-commandeArchiverGrey-width() {
  width: $menu-commandeArchiverGrey-width;
}
%menu-commandeArchiverGrey-width {
  @include menu-commandeArchiverGrey-width();
}
@mixin menu-commandeArchiverGrey-height() {
  height: $menu-commandeArchiverGrey-height;
}
%menu-commandeArchiverGrey-height {
  @include menu-commandeArchiverGrey-height();
}
@mixin menu-commandeArchiverGrey-size() {
  @include menu-commandeArchiverGrey-width();
  @include menu-commandeArchiverGrey-height();
}
%menu-commandeArchiverGrey-size {
  @include menu-commandeArchiverGrey-size();
}
@mixin menu-commandeArchiverGrey-position() {
  background-position: $menu-commandeArchiverGrey-x $menu-commandeArchiverGrey-y;
}
%menu-commandeArchiverGrey-position {
  @include menu-commandeArchiverGrey-position();
}
@mixin menu-commandeArchiverGrey-adjust() {
  @include menu-commandeArchiverGrey-size();
  @include menu-commandeArchiverGrey-position();
}
%menu-commandeArchiverGrey-adjust {
  @include menu-commandeArchiverGrey-adjust();
}
@mixin menu-commandeArchiverGrey() {
  @include menu-commandeArchiverGrey-adjust();
  @extend %menu-element;
}
%menu-commandeArchiverGrey {
  @include menu-commandeArchiverGrey();
}

$menu-commandeValiderGrey-x: -2px !default;
$menu-commandeValiderGrey-y: -172px !default;
$menu-commandeValiderGrey-width: 19px !default;
$menu-commandeValiderGrey-height: 20px !default;
$menu-commandeValiderGrey: $menu-commandeValiderGrey-x $menu-commandeValiderGrey-y $menu-commandeValiderGrey-width $menu-commandeValiderGrey-height menu-commandeValiderGrey !default;

@mixin menu-commandeValiderGrey-width() {
  width: $menu-commandeValiderGrey-width;
}
%menu-commandeValiderGrey-width {
  @include menu-commandeValiderGrey-width();
}
@mixin menu-commandeValiderGrey-height() {
  height: $menu-commandeValiderGrey-height;
}
%menu-commandeValiderGrey-height {
  @include menu-commandeValiderGrey-height();
}
@mixin menu-commandeValiderGrey-size() {
  @include menu-commandeValiderGrey-width();
  @include menu-commandeValiderGrey-height();
}
%menu-commandeValiderGrey-size {
  @include menu-commandeValiderGrey-size();
}
@mixin menu-commandeValiderGrey-position() {
  background-position: $menu-commandeValiderGrey-x $menu-commandeValiderGrey-y;
}
%menu-commandeValiderGrey-position {
  @include menu-commandeValiderGrey-position();
}
@mixin menu-commandeValiderGrey-adjust() {
  @include menu-commandeValiderGrey-size();
  @include menu-commandeValiderGrey-position();
}
%menu-commandeValiderGrey-adjust {
  @include menu-commandeValiderGrey-adjust();
}
@mixin menu-commandeValiderGrey() {
  @include menu-commandeValiderGrey-adjust();
  @extend %menu-element;
}
%menu-commandeValiderGrey {
  @include menu-commandeValiderGrey();
}

$menu-satisfactionGreen-x: -35px !default;
$menu-satisfactionGreen-y: -152px !default;
$menu-satisfactionGreen-width: 18px !default;
$menu-satisfactionGreen-height: 12px !default;
$menu-satisfactionGreen: $menu-satisfactionGreen-x $menu-satisfactionGreen-y $menu-satisfactionGreen-width $menu-satisfactionGreen-height menu-satisfactionGreen !default;

@mixin menu-satisfactionGreen-width() {
  width: $menu-satisfactionGreen-width;
}
%menu-satisfactionGreen-width {
  @include menu-satisfactionGreen-width();
}
@mixin menu-satisfactionGreen-height() {
  height: $menu-satisfactionGreen-height;
}
%menu-satisfactionGreen-height {
  @include menu-satisfactionGreen-height();
}
@mixin menu-satisfactionGreen-size() {
  @include menu-satisfactionGreen-width();
  @include menu-satisfactionGreen-height();
}
%menu-satisfactionGreen-size {
  @include menu-satisfactionGreen-size();
}
@mixin menu-satisfactionGreen-position() {
  background-position: $menu-satisfactionGreen-x $menu-satisfactionGreen-y;
}
%menu-satisfactionGreen-position {
  @include menu-satisfactionGreen-position();
}
@mixin menu-satisfactionGreen-adjust() {
  @include menu-satisfactionGreen-size();
  @include menu-satisfactionGreen-position();
}
%menu-satisfactionGreen-adjust {
  @include menu-satisfactionGreen-adjust();
}
@mixin menu-satisfactionGreen() {
  @include menu-satisfactionGreen-adjust();
  @extend %menu-element;
}
%menu-satisfactionGreen {
  @include menu-satisfactionGreen();
}

$menu-satisfactionGrey-x: 0 !default;
$menu-satisfactionGrey-y: -152px !default;
$menu-satisfactionGrey-width: 18px !default;
$menu-satisfactionGrey-height: 12px !default;
$menu-satisfactionGrey: $menu-satisfactionGrey-x $menu-satisfactionGrey-y $menu-satisfactionGrey-width $menu-satisfactionGrey-height menu-satisfactionGrey !default;

@mixin menu-satisfactionGrey-width() {
  width: $menu-satisfactionGrey-width;
}
%menu-satisfactionGrey-width {
  @include menu-satisfactionGrey-width();
}
@mixin menu-satisfactionGrey-height() {
  height: $menu-satisfactionGrey-height;
}
%menu-satisfactionGrey-height {
  @include menu-satisfactionGrey-height();
}
@mixin menu-satisfactionGrey-size() {
  @include menu-satisfactionGrey-width();
  @include menu-satisfactionGrey-height();
}
%menu-satisfactionGrey-size {
  @include menu-satisfactionGrey-size();
}
@mixin menu-satisfactionGrey-position() {
  background-position: $menu-satisfactionGrey-x $menu-satisfactionGrey-y;
}
%menu-satisfactionGrey-position {
  @include menu-satisfactionGrey-position();
}
@mixin menu-satisfactionGrey-adjust() {
  @include menu-satisfactionGrey-size();
  @include menu-satisfactionGrey-position();
}
%menu-satisfactionGrey-adjust {
  @include menu-satisfactionGrey-adjust();
}
@mixin menu-satisfactionGrey() {
  @include menu-satisfactionGrey-adjust();
  @extend %menu-element;
}
%menu-satisfactionGrey {
  @include menu-satisfactionGrey();
}

$menu-perduGreen-x: -34px !default;
$menu-perduGreen-y: -71px !default;
$menu-perduGreen-width: 24px !default;
$menu-perduGreen-height: 26px !default;
$menu-perduGreen: $menu-perduGreen-x $menu-perduGreen-y $menu-perduGreen-width $menu-perduGreen-height menu-perduGreen !default;

@mixin menu-perduGreen-width() {
  width: $menu-perduGreen-width;
}
%menu-perduGreen-width {
  @include menu-perduGreen-width();
}
@mixin menu-perduGreen-height() {
  height: $menu-perduGreen-height;
}
%menu-perduGreen-height {
  @include menu-perduGreen-height();
}
@mixin menu-perduGreen-size() {
  @include menu-perduGreen-width();
  @include menu-perduGreen-height();
}
%menu-perduGreen-size {
  @include menu-perduGreen-size();
}
@mixin menu-perduGreen-position() {
  background-position: $menu-perduGreen-x $menu-perduGreen-y;
}
%menu-perduGreen-position {
  @include menu-perduGreen-position();
}
@mixin menu-perduGreen-adjust() {
  @include menu-perduGreen-size();
  @include menu-perduGreen-position();
}
%menu-perduGreen-adjust {
  @include menu-perduGreen-adjust();
}
@mixin menu-perduGreen() {
  @include menu-perduGreen-adjust();
  @extend %menu-element;
}
%menu-perduGreen {
  @include menu-perduGreen();
}

$menu-perduGrey-x: 0 !default;
$menu-perduGrey-y: -71px !default;
$menu-perduGrey-width: 25px !default;
$menu-perduGrey-height: 26px !default;
$menu-perduGrey: $menu-perduGrey-x $menu-perduGrey-y $menu-perduGrey-width $menu-perduGrey-height menu-perduGrey !default;

@mixin menu-perduGrey-width() {
  width: $menu-perduGrey-width;
}
%menu-perduGrey-width {
  @include menu-perduGrey-width();
}
@mixin menu-perduGrey-height() {
  height: $menu-perduGrey-height;
}
%menu-perduGrey-height {
  @include menu-perduGrey-height();
}
@mixin menu-perduGrey-size() {
  @include menu-perduGrey-width();
  @include menu-perduGrey-height();
}
%menu-perduGrey-size {
  @include menu-perduGrey-size();
}
@mixin menu-perduGrey-position() {
  background-position: $menu-perduGrey-x $menu-perduGrey-y;
}
%menu-perduGrey-position {
  @include menu-perduGrey-position();
}
@mixin menu-perduGrey-adjust() {
  @include menu-perduGrey-size();
  @include menu-perduGrey-position();
}
%menu-perduGrey-adjust {
  @include menu-perduGrey-adjust();
}
@mixin menu-perduGrey() {
  @include menu-perduGrey-adjust();
  @extend %menu-element;
}
%menu-perduGrey {
  @include menu-perduGrey();
}

$menu-reclamationsGreen-x: -37px !default;
$menu-reclamationsGreen-y: -49px !default;
$menu-reclamationsGreen-width: 20px !default;
$menu-reclamationsGreen-height: 20px !default;
$menu-reclamationsGreen: $menu-reclamationsGreen-x $menu-reclamationsGreen-y $menu-reclamationsGreen-width $menu-reclamationsGreen-height menu-reclamationsGreen !default;

@mixin menu-reclamationsGreen-width() {
  width: $menu-reclamationsGreen-width;
}
%menu-reclamationsGreen-width {
  @include menu-reclamationsGreen-width();
}
@mixin menu-reclamationsGreen-height() {
  height: $menu-reclamationsGreen-height;
}
%menu-reclamationsGreen-height {
  @include menu-reclamationsGreen-height();
}
@mixin menu-reclamationsGreen-size() {
  @include menu-reclamationsGreen-width();
  @include menu-reclamationsGreen-height();
}
%menu-reclamationsGreen-size {
  @include menu-reclamationsGreen-size();
}
@mixin menu-reclamationsGreen-position() {
  background-position: $menu-reclamationsGreen-x $menu-reclamationsGreen-y;
}
%menu-reclamationsGreen-position {
  @include menu-reclamationsGreen-position();
}
@mixin menu-reclamationsGreen-adjust() {
  @include menu-reclamationsGreen-size();
  @include menu-reclamationsGreen-position();
}
%menu-reclamationsGreen-adjust {
  @include menu-reclamationsGreen-adjust();
}
@mixin menu-reclamationsGreen() {
  @include menu-reclamationsGreen-adjust();
  @extend %menu-element;
}
%menu-reclamationsGreen {
  @include menu-reclamationsGreen();
}

$menu-reclamationsGrey-x: -3px !default;
$menu-reclamationsGrey-y: -49px !default;
$menu-reclamationsGrey-width: 21px !default;
$menu-reclamationsGrey-height: 20px !default;
$menu-reclamationsGrey: $menu-reclamationsGrey-x $menu-reclamationsGrey-y $menu-reclamationsGrey-width $menu-reclamationsGrey-height menu-reclamationsGrey !default;

@mixin menu-reclamationsGrey-width() {
  width: $menu-reclamationsGrey-width;
}
%menu-reclamationsGrey-width {
  @include menu-reclamationsGrey-width();
}
@mixin menu-reclamationsGrey-height() {
  height: $menu-reclamationsGrey-height;
}
%menu-reclamationsGrey-height {
  @include menu-reclamationsGrey-height();
}
@mixin menu-reclamationsGrey-size() {
  @include menu-reclamationsGrey-width();
  @include menu-reclamationsGrey-height();
}
%menu-reclamationsGrey-size {
  @include menu-reclamationsGrey-size();
}
@mixin menu-reclamationsGrey-position() {
  background-position: $menu-reclamationsGrey-x $menu-reclamationsGrey-y;
}
%menu-reclamationsGrey-position {
  @include menu-reclamationsGrey-position();
}
@mixin menu-reclamationsGrey-adjust() {
  @include menu-reclamationsGrey-size();
  @include menu-reclamationsGrey-position();
}
%menu-reclamationsGrey-adjust {
  @include menu-reclamationsGrey-adjust();
}
@mixin menu-reclamationsGrey() {
  @include menu-reclamationsGrey-adjust();
  @extend %menu-element;
}
%menu-reclamationsGrey {
  @include menu-reclamationsGrey();
}

$menu-informationsGrey-x: 0 !default;
$menu-informationsGrey-y: -100px !default;
$menu-informationsGrey-width: 21px !default;
$menu-informationsGrey-height: 22px !default;
$menu-informationsGrey: $menu-informationsGrey-x $menu-informationsGrey-y $menu-informationsGrey-width $menu-informationsGrey-height menu-informationsGrey !default;

@mixin menu-informationsGrey-width() {
  width: $menu-informationsGrey-width;
}
%menu-informationsGrey-width {
  @include menu-informationsGrey-width();
}
@mixin menu-informationsGrey-height() {
  height: $menu-informationsGrey-height;
}
%menu-informationsGrey-height {
  @include menu-informationsGrey-height();
}
@mixin menu-informationsGrey-size() {
  @include menu-informationsGrey-width();
  @include menu-informationsGrey-height();
}
%menu-informationsGrey-size {
  @include menu-informationsGrey-size();
}
@mixin menu-informationsGrey-position() {
  background-position: $menu-informationsGrey-x $menu-informationsGrey-y;
}
%menu-informationsGrey-position {
  @include menu-informationsGrey-position();
}
@mixin menu-informationsGrey-adjust() {
  @include menu-informationsGrey-size();
  @include menu-informationsGrey-position();
}
%menu-informationsGrey-adjust {
  @include menu-informationsGrey-adjust();
}
@mixin menu-informationsGrey() {
  @include menu-informationsGrey-adjust();
  @extend %menu-element;
}
%menu-informationsGrey {
  @include menu-informationsGrey();
}

$menu-informationsGreen-x: -34px !default;
$menu-informationsGreen-y: -100px !default;
$menu-informationsGreen-width: 20px !default;
$menu-informationsGreen-height: 22px !default;
$menu-informationsGreen: $menu-informationsGreen-x $menu-informationsGreen-y $menu-informationsGreen-width $menu-informationsGreen-height menu-informationsGreen !default;

@mixin menu-informationsGreen-width() {
  width: $menu-informationsGreen-width;
}
%menu-informationsGreen-width {
  @include menu-informationsGreen-width();
}
@mixin menu-informationsGreen-height() {
  height: $menu-informationsGreen-height;
}
%menu-informationsGreen-height {
  @include menu-informationsGreen-height();
}
@mixin menu-informationsGreen-size() {
  @include menu-informationsGreen-width();
  @include menu-informationsGreen-height();
}
%menu-informationsGreen-size {
  @include menu-informationsGreen-size();
}
@mixin menu-informationsGreen-position() {
  background-position: $menu-informationsGreen-x $menu-informationsGreen-y;
}
%menu-informationsGreen-position {
  @include menu-informationsGreen-position();
}
@mixin menu-informationsGreen-adjust() {
  @include menu-informationsGreen-size();
  @include menu-informationsGreen-position();
}
%menu-informationsGreen-adjust {
  @include menu-informationsGreen-adjust();
}
@mixin menu-informationsGreen() {
  @include menu-informationsGreen-adjust();
  @extend %menu-element;
}
%menu-informationsGreen {
  @include menu-informationsGreen();
}

$menu-passerCommandeGreen-x: -38px !default;
$menu-passerCommandeGreen-y: -26px !default;
$menu-passerCommandeGreen-width: 18px !default;
$menu-passerCommandeGreen-height: 19px !default;
$menu-passerCommandeGreen: $menu-passerCommandeGreen-x $menu-passerCommandeGreen-y $menu-passerCommandeGreen-width $menu-passerCommandeGreen-height menu-passerCommandeGreen !default;

@mixin menu-passerCommandeGreen-width() {
  width: $menu-passerCommandeGreen-width;
}
%menu-passerCommandeGreen-width {
  @include menu-passerCommandeGreen-width();
}
@mixin menu-passerCommandeGreen-height() {
  height: $menu-passerCommandeGreen-height;
}
%menu-passerCommandeGreen-height {
  @include menu-passerCommandeGreen-height();
}
@mixin menu-passerCommandeGreen-size() {
  @include menu-passerCommandeGreen-width();
  @include menu-passerCommandeGreen-height();
}
%menu-passerCommandeGreen-size {
  @include menu-passerCommandeGreen-size();
}
@mixin menu-passerCommandeGreen-position() {
  background-position: $menu-passerCommandeGreen-x $menu-passerCommandeGreen-y;
}
%menu-passerCommandeGreen-position {
  @include menu-passerCommandeGreen-position();
}
@mixin menu-passerCommandeGreen-adjust() {
  @include menu-passerCommandeGreen-size();
  @include menu-passerCommandeGreen-position();
}
%menu-passerCommandeGreen-adjust {
  @include menu-passerCommandeGreen-adjust();
}
@mixin menu-passerCommandeGreen() {
  @include menu-passerCommandeGreen-adjust();
  @extend %menu-element;
}
%menu-passerCommandeGreen {
  @include menu-passerCommandeGreen();
}

$menu-passerCommandeGrey-x: -4px !default;
$menu-passerCommandeGrey-y: -26px !default;
$menu-passerCommandeGrey-width: 19px !default;
$menu-passerCommandeGrey-height: 19px !default;
$menu-passerCommandeGrey: $menu-passerCommandeGrey-x $menu-passerCommandeGrey-y $menu-passerCommandeGrey-width $menu-passerCommandeGrey-height menu-passerCommandeGrey !default;

@mixin menu-passerCommandeGrey-width() {
  width: $menu-passerCommandeGrey-width;
}
%menu-passerCommandeGrey-width {
  @include menu-passerCommandeGrey-width();
}
@mixin menu-passerCommandeGrey-height() {
  height: $menu-passerCommandeGrey-height;
}
%menu-passerCommandeGrey-height {
  @include menu-passerCommandeGrey-height();
}
@mixin menu-passerCommandeGrey-size() {
  @include menu-passerCommandeGrey-width();
  @include menu-passerCommandeGrey-height();
}
%menu-passerCommandeGrey-size {
  @include menu-passerCommandeGrey-size();
}
@mixin menu-passerCommandeGrey-position() {
  background-position: $menu-passerCommandeGrey-x $menu-passerCommandeGrey-y;
}
%menu-passerCommandeGrey-position {
  @include menu-passerCommandeGrey-position();
}
@mixin menu-passerCommandeGrey-adjust() {
  @include menu-passerCommandeGrey-size();
  @include menu-passerCommandeGrey-position();
}
%menu-passerCommandeGrey-adjust {
  @include menu-passerCommandeGrey-adjust();
}
@mixin menu-passerCommandeGrey() {
  @include menu-passerCommandeGrey-adjust();
  @extend %menu-element;
}
%menu-passerCommandeGrey {
  @include menu-passerCommandeGrey();
}

$menu-commandesGreen-x: -39px !default;
$menu-commandesGreen-y: 0 !default;
$menu-commandesGreen-width: 18px !default;
$menu-commandesGreen-height: 19px !default;
$menu-commandesGreen: $menu-commandesGreen-x $menu-commandesGreen-y $menu-commandesGreen-width $menu-commandesGreen-height menu-commandesGreen !default;

@mixin menu-commandesGreen-width() {
  width: $menu-commandesGreen-width;
}
%menu-commandesGreen-width {
  @include menu-commandesGreen-width();
}
@mixin menu-commandesGreen-height() {
  height: $menu-commandesGreen-height;
}
%menu-commandesGreen-height {
  @include menu-commandesGreen-height();
}
@mixin menu-commandesGreen-size() {
  @include menu-commandesGreen-width();
  @include menu-commandesGreen-height();
}
%menu-commandesGreen-size {
  @include menu-commandesGreen-size();
}
@mixin menu-commandesGreen-position() {
  background-position: $menu-commandesGreen-x $menu-commandesGreen-y;
}
%menu-commandesGreen-position {
  @include menu-commandesGreen-position();
}
@mixin menu-commandesGreen-adjust() {
  @include menu-commandesGreen-size();
  @include menu-commandesGreen-position();
}
%menu-commandesGreen-adjust {
  @include menu-commandesGreen-adjust();
}
@mixin menu-commandesGreen() {
  @include menu-commandesGreen-adjust();
  @extend %menu-element;
}
%menu-commandesGreen {
  @include menu-commandesGreen();
}

$menu-commandesGrey-x: -5px !default;
$menu-commandesGrey-y: 0 !default;
$menu-commandesGrey-width: 19px !default;
$menu-commandesGrey-height: 19px !default;
$menu-commandesGrey: $menu-commandesGrey-x $menu-commandesGrey-y $menu-commandesGrey-width $menu-commandesGrey-height menu-commandesGrey !default;

@mixin menu-commandesGrey-width() {
  width: $menu-commandesGrey-width;
}
%menu-commandesGrey-width {
  @include menu-commandesGrey-width();
}
@mixin menu-commandesGrey-height() {
  height: $menu-commandesGrey-height;
}
%menu-commandesGrey-height {
  @include menu-commandesGrey-height();
}
@mixin menu-commandesGrey-size() {
  @include menu-commandesGrey-width();
  @include menu-commandesGrey-height();
}
%menu-commandesGrey-size {
  @include menu-commandesGrey-size();
}
@mixin menu-commandesGrey-position() {
  background-position: $menu-commandesGrey-x $menu-commandesGrey-y;
}
%menu-commandesGrey-position {
  @include menu-commandesGrey-position();
}
@mixin menu-commandesGrey-adjust() {
  @include menu-commandesGrey-size();
  @include menu-commandesGrey-position();
}
%menu-commandesGrey-adjust {
  @include menu-commandesGrey-adjust();
}
@mixin menu-commandesGrey() {
  @include menu-commandesGrey-adjust();
  @extend %menu-element;
}
%menu-commandesGrey {
  @include menu-commandesGrey();
}

$menu-docGreen-x: -35px !default;
$menu-docGreen-y: -126px !default;
$menu-docGreen-width: 18px !default;
$menu-docGreen-height: 21px !default;
$menu-docGreen: $menu-docGreen-x $menu-docGreen-y $menu-docGreen-width $menu-docGreen-height menu-docGreen !default;

@mixin menu-docGreen-width() {
  width: $menu-docGreen-width;
}
%menu-docGreen-width {
  @include menu-docGreen-width();
}
@mixin menu-docGreen-height() {
  height: $menu-docGreen-height;
}
%menu-docGreen-height {
  @include menu-docGreen-height();
}
@mixin menu-docGreen-size() {
  @include menu-docGreen-width();
  @include menu-docGreen-height();
}
%menu-docGreen-size {
  @include menu-docGreen-size();
}
@mixin menu-docGreen-position() {
  background-position: $menu-docGreen-x $menu-docGreen-y;
}
%menu-docGreen-position {
  @include menu-docGreen-position();
}
@mixin menu-docGreen-adjust() {
  @include menu-docGreen-size();
  @include menu-docGreen-position();
}
%menu-docGreen-adjust {
  @include menu-docGreen-adjust();
}
@mixin menu-docGreen() {
  @include menu-docGreen-adjust();
  @extend %menu-element;
}
%menu-docGreen {
  @include menu-docGreen();
}

$menu-docGrey-x: -1px !default;
$menu-docGrey-y: -126px !default;
$menu-docGrey-width: 19px !default;
$menu-docGrey-height: 21px !default;
$menu-docGrey: $menu-docGrey-x $menu-docGrey-y $menu-docGrey-width $menu-docGrey-height menu-docGrey !default;

@mixin menu-docGrey-width() {
  width: $menu-docGrey-width;
}
%menu-docGrey-width {
  @include menu-docGrey-width();
}
@mixin menu-docGrey-height() {
  height: $menu-docGrey-height;
}
%menu-docGrey-height {
  @include menu-docGrey-height();
}
@mixin menu-docGrey-size() {
  @include menu-docGrey-width();
  @include menu-docGrey-height();
}
%menu-docGrey-size {
  @include menu-docGrey-size();
}
@mixin menu-docGrey-position() {
  background-position: $menu-docGrey-x $menu-docGrey-y;
}
%menu-docGrey-position {
  @include menu-docGrey-position();
}
@mixin menu-docGrey-adjust() {
  @include menu-docGrey-size();
  @include menu-docGrey-position();
}
%menu-docGrey-adjust {
  @include menu-docGrey-adjust();
}
@mixin menu-docGrey() {
  @include menu-docGrey-adjust();
  @extend %menu-element;
}
%menu-docGrey {
  @include menu-docGrey();
}

