.dialog__content{
  text-align: center;
  h2{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 35px;

    &.red{
      color: $barbie-pink;
    }

    &.green{
      color:  $aquamarine;
    }
  }

  .content{
    p{
      font-size: 16px;
      font-weight: 600;
      line-height: 1.56;
      text-align: center;
      color: $black;

      span{
        &.green{
          color: $aquamarine;
        }

        &.red{
            color: $barbie-pink;
        }
      }
    }
  }

  #reponse{
    max-width: 610px;
    margin: 0px auto;
    margin-top: 8px;
    display: none;
  }

  .statut_select{
    margin: 0px auto;
    margin-top: 50px;

  }

  .buttons_container{
    text-align: center;
    margin-top: 60px;

    .button, .button_wrap{
      display: inline-block !important;

      &:not(:last-child){
        margin-right: 30px;
      }

      @include breakpoint(small down){
        width: 100% !important;
        margin-right: 0 !important;
        &:not(:last-child){
          margin-bottom: 20px;
        }
      }
    }

    .button, .button_wrap a{
      min-width: 150px;
      padding-left: 35px;
      padding-right: 35px;
    }

    .button.red, .button_wrap.red a{
      @include buttonRounded($barbie-pink,  #c1c1c1);
    }
    .button.green, .button_wrap.green a{
      @include buttonRounded($aquamarine,  #c1c1c1);
    }
    .button.grey, .button_wrap.grey a{
      @include buttonRounded( #c1c1c1, $aquamarine);
    }

    .button_wrap{
      .flag-throbber{
        display: none !important;
      }
    }
  }
}


#messages{
  .dialog__content{
    max-width: 400px;
    padding-top: 50px;
    padding-bottom: 50px;

    .content_message{
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
      padding-top: 62px;
      position: relative;

      .messages{
        & + i {
          display: block;
          margin: 0px auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          cursor: pointer;
        }
        &.status + i {
          @include global-valider;
        }
        &.error + i {
          @include global-refuser;
        }

        a {
          color: $aquamarine;
        }
      }
    }
  }
}

#dialog_tailles .content_message p {
  font-size: 24px;
  font-weight: 700;
}
