$grid-row-width: 1144px;
$columns: 12;
$gutters: 20px;


/*$breakpoints: (
  small: 0px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
*/
@mixin container {
  	@include grid-row($columns, $gutters, $grid-row-width);

	@include breakpoint(large down) {
	max-width: 900px; 
	}	

	@include breakpoint(medium down) {
		max-width: 600px; 
	}

	@include breakpoint(small down) {
		max-width: 100%; 
		padding: 0px 15px; 
	}
}

@mixin row() {
	@include clearfix; 
	margin: 0px -10px; 
}

.container{
	@include container; 
}


.row{
	@include clearfix; 
	margin: 0px -10px; 
}