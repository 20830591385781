@mixin buttonSeeMore{
  width: 32px;
  height: 32px;
	border: solid 1px $black;
  background: #fff;
  text-align: center;
  line-height: 31px;
  font-size: 17px;
  font-weight: 600;
  color: $black-two;
  display: block;
  border-radius: 50%;
  &:hover{
    background:  $black-two;
    color: #fff;
  }
}



  @mixin buttonRounded($bgCOlor, $bgHoverColor, $width:auto, $icon:null){
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 25px;
    width: $width;
    padding: 0;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 100%;
    background-color: $bgCOlor;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    appearance:none;
    border: none;
    display: block;
    cursor: pointer;

    @include breakpoint(small down){
      width: 100%; ;
    }

    &:hover{
      background: $bgHoverColor;
    }

    @if($icon == 'back'){
      padding-left: 75px;
      text-align: left;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: 14px;
        left: 27px;
        position: absolute;
        @include global-inButtonBackWhite;
      }
    }
  }


  @mixin buttonRoundedBorder($borderColor, $bgCOlor, $width:auto){
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 25px;
    width: $width;
    padding: 0;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 100%;
    background-color: $bgCOlor;
    font-size: 16px;
    font-weight: 600;
    color: $borderColor;
    appearance:none;
    border: none;
    display: block;
    cursor: pointer;
    border: solid 1px $borderColor;

    &:hover{
      background: $borderColor;
      color: $bgCOlor;
    }

    @include breakpoint(small down){
      width: 100%; ;
    }

  }
